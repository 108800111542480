import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useFetch } from "../../hooks/useFecth";
import Logo from "../atoms/Logo";
import { useContext, useEffect, useState } from "react";
import HandlerApp from "../../utils/handlerApp";
import Joyride, { ACTIONS, EVENTS, STATUS, Step } from 'react-joyride'; 
import { AppContext } from "../../context/AppContext";


const Aside = ({ loaderMenu, menu, run, setRun, menuRef, collapse, setCollapse }) => {
    const url = window.location.pathname.substring(1);
    const [t, i18n] = useTranslation("global");
    const api = useFetch();
    const handlerApp = HandlerApp();

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [stepIndex, setStepIndex] = useState(0);
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const [lastSelectedNum, setLastSelectedNum] = useState(null);
    const { modeTheme } = useContext(AppContext); 

    const [menuParent, setMenuParent] = useState();
    const [menuChild, setMenuChild] = useState();

    useEffect(()=>{
        let indexParent = null;
        let indexChild = null;
        menu.forEach( (item, index) =>{
            item.childrens.forEach( (child, childIndex) => {
                if(url === child.menu_children_action){
                    indexParent = index;
                    indexChild = childIndex;
                }
            })
        })
        setMenuParent(indexParent);
        setMenuChild(indexChild);

        setLastSelectedIndex(indexParent);
        setLastSelectedNum(indexChild);
    },[menu])

    const handleRef = (index) => (element) => {
        menuRef.current[index] = element; // Assign ref to corresponding index in array
    };

    if (loaderMenu === true){
        return (
            <>
                <div className="bgAside-dark" >
                    <Skeleton variant="circular" sx={{ bgcolor: 'white' }} width={60} height={60} />
                    <Skeleton variant="rounded" sx={{ bgcolor: 'white' }} height={40} />
                    <Skeleton variant="rounded" sx={{ bgcolor: 'white' }} height={40} />
                </div>
            </>
        )
    }
    const steps = [
        {
            target: 'body',
            content: <div>
                <h4>{t('tutorial.headerFirst')}</h4>
                <p>{t('tutorial.headerFirstP')}</p>
            </div>,
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'center',
        },
        {
            target: '#my-first-step',
            content: t('tutorial.menuHide'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },

        },
        {
            target: '#my-second-step',
            content: t('tutorial.selectLocation'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },

        },
        {
            target: '#my-step',
            content: t('tutorial.tuto'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },

        },
        {
            target: '#my-third-step',
            content: t('tutorial.language'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },

        },
        {
            target: '#my-fourth-step',
            content: t('tutorial.user'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'left',

        },
        {
            target: '#menuTitle0',
            content: t('tutorial.arrow'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            spotlightClicks: true,
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },


        },
        {
            target: '#menuAction00',
            content: t('tutorial.companyMenu'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuAction01',
            content: t('tutorial.locationMenu'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuTitle0',
            content: t('tutorial.arrow'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            spotlightClicks: true,
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuTitle1',
            content: t('tutorial.arrow'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            spotlightClicks: true,
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuAction10',
            content: t('tutorial.productsMenu'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuAction11',
            content: t('tutorial.categoriesMenu'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuAction12',
            content: t('tutorial.subcategoriesMenu'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuAction13',
            content: t('tutorial.productsLocationMenu'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuAction14',
            content: t('tutorial.itemsMenu'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuAction15',
            content: t('tutorial.dessertMenu'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuAction16',
            content: t('tutorial.pizzaMenu'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuTitle1',
            content: t('tutorial.arrow'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            spotlightClicks: true,
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuTitle2',
            content: t('tutorial.arrow'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            spotlightClicks: true,
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuAction20',
            content: t('tutorial.users'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuAction21',
            content: t('tutorial.usersLocation'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuTitle2',
            content: t('tutorial.arrow'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            spotlightClicks: true,
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuTitle3',
            content: t('tutorial.transactions'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuTitle4',
            content: t('tutorial.taxes'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuTitle5',
            content: t('tutorial.orders'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuTitle6',
            content: t('tutorial.arrow'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            spotlightClicks: true,
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuAction60',
            content: t('tutorial.printers'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuAction61',
            content: t('tutorial.alarms'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuTitle6',
            content: t('tutorial.arrow'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            spotlightClicks: true,
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuTitle7',
            content: t('tutorial.arrow'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            spotlightClicks: true,
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
        {
            target: '#menuAction70',
            content: t('tutorial.config'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'right',
            styles: {
                options: {
                    width:"230px",
                }
            },
        },
    ]

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setRun(false);
            setStepIndex(0);
            updateStatusUserTutorial();
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

            if (sidebarOpen && index === 0) {
                setTimeout(() => {
                    setRun(true);
                }, 400);
            } else if (sidebarOpen && index === 1) {
                setRun(false);
                setSidebarOpen(false);
                setCollapse(false);
                setStepIndex(nextStepIndex);
                setTimeout(() => {
                    setRun(true);
                }, 400);
            } else if (index === 2 && action === ACTIONS.PREV) {
                setRun(false);
                setSidebarOpen(true);
                setStepIndex(nextStepIndex);
                setTimeout(() => {
                    setRun(true);
                }, 400);
            } else {
                setSidebarOpen(false);
                setStepIndex(nextStepIndex);
            }
        }
    };

    const updateStatusUserTutorial = () => {
        handlerApp.setLoader(true);
        api.put("/administration/users/update-status-tutorial/user_tutorial/1")
        .then(response => {
            handlerApp.setLoader(false);
        })
        .catch(error => {
            handlerApp.setLoader(false);
        });
    };

    const handleClick = (num, index) => {
        setLastSelectedNum(num);
        setLastSelectedIndex(index); // Update the last selected item state
    };

    const renderMenu = (menu) => {
        const getMenuName = (menuItem) => {
            return i18n.language === "en" ? menuItem.menu_name : menuItem.menu_name_spanish;
        };

        const getMenuChildName = (menuItem) => {
            return i18n.language === "en" ? menuItem.menu_children_name : menuItem.menu_children_spanish;
        };

        return (
            <ul className="menu-list" id="menu-list">
                <li className="menu-item" onClick={() => handleClick(null, null)}>
                    <NavLink to="/" style={{ backgroundColor: (lastSelectedIndex === null && lastSelectedNum === null ? "#636262" : "") }}>
                        <span className="material-symbols-outlined">home</span>
                        {t('home')}
                    </NavLink>
                </li>
                {menu.map((title, index) => (
                    <li key={"parent-" + index} className="menu-item collapsable">
                        <a
                            key={"anchor-" + index}
                            className={ (index === menuParent) ? "" : "collapsed"}
                            data-bs-toggle="collapse"
                            href={"#collapse-" + removeSpaces(title.menu_parent)}
                            role="button"
                            aria-expanded="false"
                            aria-controls={"collapse-" + removeSpaces(title.menu_parent)}
                            id={'menuTitle' + index}
                            onClick={() => setStepIndex((stepIndex) => stepIndex + 1)}
                        >
                            <span className="material-symbols-outlined">{title.menu_icon}</span>
                            {getMenuName(title)}
                        </a>
                        <div ref={handleRef("collapse-" + removeSpaces(title.menu_parent))} className={(index === menuParent) ? "collapse show" : "collapse"} id={"collapse-" + removeSpaces(title.menu_parent)}>
                            <ul>
                                {title.childrens.map((child, num) => (
                                    <li className="menu-item menu-children" key={"child-" + num} onClick={() => handleClick(num, index)}>
                                        <NavLink style={{ backgroundColor: (lastSelectedIndex === index && lastSelectedNum === num ? "#636262" : "") }} className={(num === menuChild) ? "active" : ""} id={'menuAction' + index + num} to={child.menu_children_action}>
                                            {getMenuChildName(child)}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <>
            <Joyride
                steps={steps}
                showProgress
                showSkipButton
                callback={handleJoyrideCallback}
                run={run}
                stepIndex={stepIndex}
                continuous 
                styles={ modeTheme ?
                    {options: 
                        {
                        arrowColor: '#20222a',
                        primaryColor: '#da3452',
                        backgroundColor: '#20222a',
                        textColor: '#FFFFFF'
                     },
                     buttonBack: {
                        marginRight: 10,
                        color: '#fff',
                      },
                    }
                     :
                    {options: 
                        {primaryColor: '#202742'}
                    }
                }
                />
            <div className="bgAside-dark" >
                <div className="brand-logo">
                    <Logo />
                </div>
                {i18n.language === "en" ? renderMenu(menu) : renderMenu(menu.map(m => ({ ...m, menu_parent: m.menu_name_spanish, childrens: m.childrens.map(c => ({ ...c, menu_children: c.menu_children_spanish })) })))}
            </div>
        </>
    );

    function removeSpaces(inputValue) {
        return inputValue.replace(/ /g, "")
    }
};

export default Aside;