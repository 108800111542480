import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


const BoldSelectValidated = ({title, name, options,onChange=null, message=null, value,dependency=null, register, errors, required=false,disable=false })=>{
    const [val,setVal] = useState("");
    const [t] = useTranslation("global");
   
    useEffect(()=>{
        //console.log(value,'valueee');
        if(value){
            if(options.length > 0){
                setVal(value);
            }
        }
    },[value])




    const handleChange = (e)=>{   
        setVal(e.target.value);
        if(onChange!= null){
            onChange(e,name,dependency);
        }
    }
   
    return (
        <FormControl className="w-100" variant="standard" size="small">
            <InputLabel id={`${title}-label`}>{title}</InputLabel>
            <Select labelId={`${title}-label`} className="barberlytics-select"
                 {...register(name,{ required: required})}


                value={value}
                disabled={disable}
                onChange={handleChange} 
                label={title} 
                error={errors[name]!= null}
            >
                <MenuItem value="">
                    <em>...</em>
                </MenuItem>
                {options.map(item => (
                <MenuItem value={item.code} key={item.code}>
                    {item.name}
                </MenuItem>
            ))}




                
            </Select>
            {errors[name]!= null && <FormHelperText className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">{
                errors[name]?.type === 'required' ? message : ""
                }</FormHelperText>}
        </FormControl>
    )
}

export default BoldSelectValidated;






























// import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
// import { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";


// const BoldSelectValidated = ({title, name, options,onChange=null, value,dependency=null, register, errors, required=false    })=>{
//     const [val,setVal] = useState("");
//     const [t] = useTranslation("global");
   
//     useEffect(()=>{
//         if(value){
//             if(options.length > 0){
//                 setVal(value);
//             }
//         }
//     },[])





//     const handleChange = (e)=>{   
//         setVal(e.target.value);
//         if(onChange!= null){
//             onChange(e,name,dependency);
//         }
//     }
//     const optionsArray = Object.entries(options).map(([code, name]) => ({ code, name }));
//     return (
//         <FormControl className="w-100 bg-white" variant="standard" size="small">
//             <InputLabel id={`${title}-label`}>{title}</InputLabel>
//             <Select labelId={`${title}-label`} className="barberlytics-select"
//                  {...register(name,{ required: required})}
//                 value={val} 
//                 onChange={handleChange} 
//                 label={title} 
//                 error={errors[name]!= null}
//             >
//                 <MenuItem value="">
//                     <em>...</em>
//                 </MenuItem>
//                 {optionsArray.map(item => (
//                 <MenuItem value={item.code} key={item.code}>
//                     {item.name}
//                 </MenuItem>
//             ))}




                
//             </Select>
//             {errors[name]!= null && <FormHelperText className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">{
//                 errors[name]?.type === 'required' ? "the field is required" : ""
//                 }</FormHelperText>}
//         </FormControl>
//     )
// }

// export default BoldSelectValidated;