import { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HandlerApp from "../../../utils/handlerApp";
import Button from '@mui/material/Button';
import ConfirmationModal from "../Transactions/ConfirmationModal";

const TransactionsView = () => {
    const endpoint = 'transactions'
    const endpointRefund = 'transactions/refund'
    const { id } = useParams();
    const [t, i18n] = useTranslation("global");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState({});
    const [card, setCard] = useState({});
    const [title, setTitle] = useState("");
    const nav = useNavigate();
    const profile = localStorage.getItem("profile");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState('');
    const [actionType, setActionType] = useState('');

    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint + '/' + id)
            .then(response => {
                if (response.success) {
                    handlerApp.setLoader(false);
                    let record = response.data[0]
                    setData(record)
                    setCard(JSON.parse(record.tx_card));
                    setTitle('Transaction: ' + record.tx_code)

                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }, []);

    const back = () => {
        nav(-1);
    }

    const handleSubmit = (id) => {
        setSelectedOrderId(id);
        setActionType('REFUND');
        setIsModalOpen(true);
    };

    const handleConfirmModal = () => {
        handlerApp.setLoader(true);
        let body = {
            selectedOrderId
        };
        api.post(endpointRefund, body)
            .then((response) => {
                if (response.success) {
                    setIsModalOpen(false);
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav(-1);
                } else {
                    setIsModalOpen(false);
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch((error) => {
                setIsModalOpen(false);
                handlerApp.showError(error);
                handlerApp.setLoader(false);
            });
    };

    const handleCloseModal = () => {
        setActionType('');
        setSelectedOrderId('');
        setIsModalOpen(false);
    };

    const styleCanceled = {
        background: '#d32f2f',
        color: 'white',
        height: '20px',
        width: '40%',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 10px'
    };

    const styleSuccess = {
        background: '#56AC40',
        color: 'white',
        height: '20px',
        width: '40%',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 10px'
    };

    return (
        <div className="app">
            <div className="container bold-container-content2">
                <div className="d-flex align-items-center back-arrow">
                    <div className="d-flex row justify-content-between align-items-center arrow-back-icon2 ">
                        <span className="material-symbols-outlined arrowBack backArrow " role="button" onClick={back}>arrow_back</span>
                    </div>
                    <div style={{ marginLeft: '10px' }}>{title}</div>
                </div>
            </div>
            <div className="container bold-container-content">
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <dt>ID: </dt>
                        <dd>{data.tx_id || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt> {t('transactionsComponent.code')}</dt>
                        <dd>{data.tx_code}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt> {t('ordersComponent.order')}</dt>
                        <dd>{data.order_id || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    {(data.tx_method === "credit_card" || data.tx_method === "debit_card") &&
                        <div className="col-md-6 mb-2">
                            <dt> {t('transactionsComponent.order_clover')}</dt>
                            <dd>{data.tx_order_id_clover || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                        </div>
                    }

                    {(data.tx_method === "credit_card" || data.tx_method === "debit_card") &&
                        <div className="col-md-6 mb-2">
                            <dt> {t('transactionsComponent.payment_colver')}</dt>
                            <dd>{data.tx_payment_id_clover || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                        </div>
                    }
                    <div className="col-md-6 mb-2">
                        <dt> {t('transactionsComponent.processor')}</dt>
                        <dd>{data.tx_processor || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt> {t('transactionsComponent.transaction_status')}</dt>
                        <dd>{data.tx_transaction_status || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt> {t('transactionsComponent.transaction_id')}</dt>
                        <dd>{data.tx_transaction_id || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt> {t('transactionsComponent.method')}</dt>
                        <dd>{data.tx_method || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    {(data.tx_method === "credit_card" || data.tx_method === "debit_card") &&
                        <div className="col-md-6 mb-2">
                            <dt> {t('transactionsComponent.card')}</dt>
                            <dd>{'**' + card.last4 || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                        </div>
                    }
                    <div className="col-md-6 mb-2">
                        <dt> {t('transactionsComponent.amount')}</dt>
                        <dd>{handlerApp.formatCurrency(data.tx_amount) || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt> {t('transactionsComponent.fee')}</dt>
                        <dd>{handlerApp.formatCurrency(data.tx_fee) || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt> {t('transactionsComponent.create_by')}</dt>
                        <dd>{data.created_by || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('transactionsCustomer.transactionType')}:</dt>
                        <dd>
                            {data.tx_order_status !== 'R' && (
                                <div style={styleSuccess}>{t('transactionsCustomer.charge')}</div>
                            )}
                            {data.tx_order_status === 'R' && (
                                <div style={styleCanceled}>{t('transactionsCustomer.refund')}</div>
                            )}
                        </dd>
                    </div>
                </div>
                {
                    (profile == 3 || profile == 4 ) && data.tx_order_status !== 'R' && (
                        <div className="row">
                            <div className="col-md-2">
                                <Button variant="outlined" color="error" onClick={() => handleSubmit(data.tx_transaction_id)}>
                                    {t('transactionsCustomer.refund')}
                                </Button>
                            </div>
                        </div>
                    )
                }
                <ConfirmationModal
                    isOpen={isModalOpen}
                    actionType={actionType}
                    handleConfirm={handleConfirmModal}
                    handleClose={handleCloseModal}
                />
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TransactionsView;