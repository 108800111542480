import HandlerApp from "../../utils/handlerApp";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Logout } from "../../utils/BoldLogin";
import { useFetch } from "../../hooks/useFecth";
import { AppContext } from "../../context/AppContext";
import { useEffect, useState, useContext } from "react";
import { LocationContext } from "../../context/LocationContext";

const MainHeader = ({ toggleAside, handleLocation, run, collapseProducts, handleCompany}) => {

    const logout            = Logout();
    const [t, i18n]         = useTranslation("global");
    const nav               = useNavigate();
    const handlerApp        = HandlerApp()
    const api               = useFetch();
    const { setModeTheme }  = useContext(AppContext);

    const user              = localStorage.getItem('user');
    const profile           = localStorage.getItem("profile");
    
    const [language, setLanguage]       = useState("");
    const [isDarkMode, setIsDarkMode]   = useState(localStorage.getItem('darkMode') === 'dark');

    const {locations, companies,companyLabel,locationLabel } = useContext(LocationContext);

    useEffect(() => {
        i18n.changeLanguage(language)
        localStorage.setItem('language', language);
    }, [language])
    

    useEffect(() => {
        if (localStorage.getItem("language")) {
            i18n.changeLanguage(localStorage.getItem("language"))
        }
        const storedMode = localStorage.getItem('darkMode') === 'dark';
        const htmlElement = document.documentElement;
        if (storedMode) {
            htmlElement.classList.add('dark');
        } else {
            htmlElement.classList.remove('dark');
        }
        setIsDarkMode(storedMode);
    }, []);

    const divRoot = document.getElementById('root'); 
    divRoot.setAttribute('spellcheck', 'false'); 

    
    const toggleDarkMode = () => {
        const newIsDarkMode = !isDarkMode;
        const htmlElement = document.documentElement;
        const modeToStore = newIsDarkMode ? 'dark' : '';
        htmlElement.classList.toggle('dark', newIsDarkMode);
        setIsDarkMode(newIsDarkMode);
        localStorage.setItem('darkMode', modeToStore);
        setModeTheme(modeToStore);
    };
    const handleLogout = () => {
        logout.closeSession();
    }
    const handleAccount = () => {
        handlerApp.setLoader(true);
        let user = handlerApp.getUser();
        if (profile == 3) {
            nav('/configuration/profile/' + user);
        } else {
            nav('/configuration/profile/' + user + '/edit');
        }
        handlerApp.setLoader(false);
    }
    const initLocationTutorial = () => {
        handlerApp.setLoader(true)
        api.put("/administration/users/update-status-tutorial/user_tutorial_location/0")
        .then(response => {
            handlerApp.setLoader(false)
            nav('/administration/locations')
        })
        .catch(error => {
            handlerApp.setLoader(false)

        });
        
    }
    const initProductsTutorial = () => {
        handlerApp.setLoader(true)
        api.put("/administration/users/update-status-tutorial/user_tutorial_products/0")
        .then(response => {
            handlerApp.setLoader(false)
            collapseProducts.classList.add("show")
            nav('/administration/products')
        })
        .catch(error => {
            handlerApp.setLoader(false)

        });
    }
    

    return (
        <>
            <div className="container-menu d-flex align-items-center justify-content-between">
                <div className="d-flex">
                    <span id='my-first-step' onClick={toggleAside} className="material-symbols-outlined" role="button">menu</span>
                </div>
                <div className="actions-dashboard">
                    {profile < 3 &&
                        <div  id='my-second-step'  className="btn-action-dash dropdown">
                            <button className="btn-action-dash dropdown-toggle btn-location" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="location-label">{companyLabel}</span>
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <button className="dropdown-item" onClick={() => { handleCompany("") }}>{t('globalCompany')}</button>
                                </li>
                                {
                                    companies.map(e => {
                                        return (<li key={e.company_id}>
                                            <button className="dropdown-item" onClick={() => { handleCompany(e.company_id) }}>{e.company_name}</button>
                                        </li>)
                                    })
                                }
                            </ul>
                        </div>
                    }
                    <div  id='my-second-step'  className="btn-action-dash dropdown">
                        <button className="btn-action-dash dropdown-toggle btn-location" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="location-label">{locationLabel}</span>
                        </button>
                        <ul className="dropdown-menu">
                            <li>
                                <button className="dropdown-item" onClick={() => { handleLocation("") }}>{t('globalLocation')}</button>
                            </li>
                            {
                                locations.map(e => {
                                    return (<li key={e.location_id}>
                                        <button className="dropdown-item" onClick={() => { handleLocation(e.location_id) }}>{e.location_name}</button>
                                    </li>)
                                })
                            }
                        </ul>
                    </div>
                    <div  id='my-step' className="btn-action-dash dropdown">
                        <button className="btn-action-dash dropdown-toggle margin-cero-padding-cero" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="location-label hidden">{t('tutorial.help')}</span>
                            <span className="material-symbols-outlined ms-1">help</span>
                        </button>
                        <ul className="dropdown-menu">
                            <li>
                                <button className="dropdown-item" onClick={run}>{t('tutorial.firstTutorial')}</button>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={initLocationTutorial}>{t('tutorial.generalTutorial')}</button>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={initProductsTutorial}>{t('tutorial.productsTutorial')}</button>
                            </li>
                        </ul>
                    </div>
                    <button className="btn-action-dash d-flex" onClick={run} >
                    </button>
                    <div className="btn-action-dash dropdown">
                        <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <span id='my-third-step'  className="material-symbols-outlined">language</span>
                        </button>
                        <ul className="dropdown-menu">
                            <li>
                                <button className={language === "en" ? 'dropdown-item selected' : 'dropdown-item'} onClick={() => { setLanguage("en") }}>EN</button>
                            </li>
                            <li>
                                <button className={language === "es" ? 'dropdown-item selected' : 'dropdown-item'} onClick={() => { setLanguage("es") }}>ES</button>
                            </li>
                        </ul>
                    </div>
                    <div className="btn-action-dash dropdown col-mds">
                        <button  id='my-fourth-step' className="btn-action-dash dropdown-toggle user-account" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="location-label hidden"> {user} </span>
                            <span className="material-symbols-outlined">account_circle</span>
                        </button>
                        <ul className="dropdown-menu">
                            <li>
                                <NavLink to="/">
                                    <button className="dropdown-item">{t('home')}</button>
                                </NavLink>
                                <button className="dropdown-item" onClick={handleAccount}>{t("myAccount")}</button>
                                <button className="dropdown-item" onClick={toggleDarkMode}>
                                    {isDarkMode ? t('themesMode.desactive') : t('themesMode.active')}
                                </button>
                                <button className="dropdown-item" onClick={handleLogout}>{t("logout")}</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MainHeader;