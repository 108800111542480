import Swal from "sweetalert2";
import HandlerApp from "../../../utils/handlerApp";
import SelectLocation from "../../molecules/SelectLocation";
import InfiniteAddItems from "../../../utils/InfiniteAddItems/InfiniteAddItems";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { useContext, useEffect, useState } from "react";
import { LocationContext } from "../../../context/LocationContext";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";


const DessertsConfiguration = () => {
    const endpoint      = "configuration/products/desserts";
    const [t]           = useTranslation("global");
    const title         = t('dessertConfigurationComponent.dessertsConfiguration');
    const profile       = localStorage.getItem("profile");
    const api           = useFetch();
    const nav           = useNavigate();
    const handlerApp    = HandlerApp();

    const [data, setData] = useState([]);
    const [showUpdate, setShowUpdate] = useState(false)
    
    // location
    const { location, company } = useContext(LocationContext);
    
    useEffect(() => {
        if(profile < 3){
            if (company == "") {
                if (handlerApp.getLocation() === null || handlerApp.getLocation() === "") {
                    handlerApp.showAlertCompany();
                }
            }else if( location === ""){
                if (handlerApp.getLocation() === null || handlerApp.getLocation() === "") {
                    handlerApp.showAlertLocation();
                }
            } else {
                getParamsUpdate();
            }
        }else{
            if (location === "") {
                if (handlerApp.getLocation() === null || handlerApp.getLocation() === "") {
                    handlerApp.showAlertLocation();
                }
            } else {
                getParamsUpdate();
            }
        }
    }, [location, company])

    // Data
    const [formData, setFormData] = useState({
        dessertconfiguration_configuration: "",
        dessertconfiguration_id: "",
        desserts: []
    });

    const handleDependencies = (data) => {
        setData(data);
        let d = handlerApp.filterData(data, formData);
        setFormData(d);
    }

    const deleteRow = (e) => {
        e.preventDefault();
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + formData.dessertconfiguration_id)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const [dessertsConfiguration, setDessertsConfigurations] = useState([]);
    const [desserts, setDesserts] = useState([]);
    const [fields, setFields] = useState();

    useEffect(() => {
        setFields([
            {
                type: "select",
                name: "product_id",
                label: t('productComponent.product'),
                values: desserts,
                variable: 'numeric'
            },
            {
                type: "percent",
                name: "dessertgroup_discount",
                label: t('productComponent.discount'),
                variable: 'numeric'
            }
        ])
    }, [desserts])

    const sendRequest = () => {
        setData([])
        setFormData({
            dessertconfiguration_configuration: "",
            dessertconfiguration_id: "",
            desserts: []
        })
        api.get(endpoint + "/" + location)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                if (data.dessertconfiguration_id === 0) {
                    data.dessertconfiguration_id = null
                }
                data.desserts = JSON.parse(data.desserts);
                handleDependencies(data);
                handlerApp.setLoader(false);
                setShowUpdate(true)
            } else {
                handleDependencies([]);
                handlerApp.setLoader(false);
                setShowUpdate(false)
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError();
        })
    }

    const getParamsUpdate = () => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate",
            location
        })
        .then(response => {
            if (response.success) {
                setDessertsConfigurations(handlerApp.getResultFromObject(response.data, 'desserts_configuration'));
                setDesserts(handlerApp.getResultFromObject(response.data, 'desserts'));
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError();
        })
    }

    const handleInfiniteAdd = (result, name) => {
        setFormData({ ...formData, [name]: result });
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? e.target.name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const back = () => {
        nav(-1);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let emptyData = 0
        if(formData.dessertconfiguration_configuration  !== ""){
            if(formData.dessertconfiguration_configuration === "FIXED" && (formData.desserts.length == undefined || formData.desserts.length == 0)){
                Swal.fire({
                    title: '',
                    text: t('dessertSelect'),
                    icon: 'warning'
                });
            }else if(formData.dessertconfiguration_configuration !== "FIXED"){
                handlerApp.setLoader(true);
                let body = { ...formData, 'location_id': handlerApp.getLocation() }
                api.post(endpoint, body)
                .then(response => {
                    if (response.success) {
                        handlerApp.showOk(response);
                        handlerApp.setLoader(false);
                        nav("/" + endpoint);
                        sendRequest();
                    } else {
                        handlerApp.handlerResponse(response);
                        handlerApp.setLoader(false);
                    }

                })
                .catch(error => {
                    handlerApp.showError();
                    handlerApp.setLoader(false);
                })
            }else{
                formData.desserts.forEach(element => {
                    if (formData.desserts.length <= 5) {
                        if (isNaN(element.product_id) || element.dessertgroup_discount === null) {
                            emptyData++;
                        }
                    }
                });
                if (emptyData === 0) {
                    if (formData.desserts.length <= 5) {
                        handlerApp.setLoader(true);
                        let body = { ...formData, 'location_id': handlerApp.getLocation() }
                        api.post(endpoint, body)
                        .then(response => {
                            if (response.success) {
                                handlerApp.showOk(response);
                                handlerApp.setLoader(false);
                                nav("/" + endpoint);
                                sendRequest();
                            } else {
                                handlerApp.handlerResponse(response);
                                handlerApp.setLoader(false);
                            }
            
                        })
                        .catch(error => {
                            handlerApp.showError();
                            handlerApp.setLoader(false);
                        })
                    }else{
                        Swal.fire({
                            title: '',
                            text: t('dessertConfigurationComponent.desserts_max'),
                            icon: 'warning'
                        });
                    }
                }else{
                    Swal.fire({
                        title: '',
                        text: t('dessertConfigurationComponent.desserts_empty'),
                        icon: 'warning'
                    });
                }
            }
        }else{
            Swal.fire({
                title: '',
                text: t('dessertConfigurationComponent.emptyConfigurationDesserts'),
                icon: 'warning'
            });
        }
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        let emptyData = 0        
        if(formData.dessertconfiguration_configuration === "FIXED" && (formData.desserts.length == undefined || formData.desserts.length == 0)){
            Swal.fire({
                title: '',
                text: t('dessertSelect'),
                icon: 'warning'
            });
        }else{
            formData.desserts.forEach(element => {
                if (formData.desserts.length <= 5) {
                    if (isNaN(element.product_id) || element.dessertgroup_discount === null) {
                        emptyData++;
                    }
                }
            });
            if (emptyData === 0) {
                if (formData.desserts.length <= 5) {
                    handlerApp.setLoader(true);
                    let body = { ...formData, 'location_id': handlerApp.getLocation() }
                    let id = formData.dessertconfiguration_id
                    api.put(endpoint + "/" + id, body)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            handlerApp.setLoader(false);
                            nav("/" + endpoint);
                            sendRequest();
                        } else {
                            handlerApp.handlerResponse(response);
                            handlerApp.setLoader(false);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError();
                        handlerApp.setLoader(false);
                    })
                }else{
                    Swal.fire({
                        title: '',
                        text: t('dessertConfigurationComponent.desserts_max'),
                        icon: 'warning'
                    });
                }
            }else{
                Swal.fire({
                    title: '',
                    text: t('dessertConfigurationComponent.desserts_empty'),
                    icon: 'warning'
                });
            }
        }
    }

    const handleKeyDow = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    return (
        <>
            {location === "" &&
                <SelectLocation back={back} />
            }
            {location !== "" && (
                <div className="app container">
                    <div className="d-block text-center mb-4 title-dessert-config">
                        <h3>{title}</h3>
                    </div>

                    <div className="section-forms">
                        <form onKeyDown={handleKeyDow}>
                            <div className="row">
                                <div className="col-md-12 mb-2 ph-20">
                                    <FormControl className="w-100" variant="standard" size="small">
                                        <FormLabel id="product_view">{t('dessertConfigurationComponent.configuration')}</FormLabel>

                                        <span className="text-gray light medium-text mb-3">{t('dessertConfigurationComponent.configurationDescription')}</span>
                                        {
                                            (formData.dessertconfiguration_id != '') &&
                                            (
                                                <div className="section-events  text-end">
                                                    <button className="btn-table-actions " onClick={deleteRow}>
                                                        <span className="material-symbols-outlined">delete</span>
                                                    </button>
                                                </div>
                                            )
                                        }
                                        <div className="row">
                                            <RadioGroup
                                                aria-labelledby="product_view"
                                                name="dessertconfiguration_configuration"
                                                value={formData.dessertconfiguration_configuration}
                                                onChange={handleForm}
                                            >
                                                {dessertsConfiguration.map(e => (
                                                    <div key={e.code} className="row mb-2">
                                                        <FormControlLabel className="col-md-5" key={e.code} value={e.code} control={<Radio />} label={e.name} />
                                                        {(e.code === "RANDOM") && <span className="text-gray light medium-text col-md-6 d-flex align-items-center">{t('dessertConfigurationComponent.randomDescription')}</span>}
                                                        {(e.code === "FIXED") && <span className="text-gray light medium-text col-md-6 d-flex align-items-center">{t('dessertConfigurationComponent.fixedDescription')}</span>}
                                                        {(e.code === "TOP SALES") && <span className="text-gray light medium-text col-md-6 d-flex align-items-center">{t('dessertConfigurationComponent.topSalesDescription')}</span>}
                                                        {(e.code === "TOP 10 RANDOM") && <span className="text-gray light medium-text col-md-6 d-flex align-items-center">{t('dessertConfigurationComponent.top10Description')}</span>}
                                                    </div>))}
                                            </RadioGroup>
                                        </div>
                                        {(formData.dessertconfiguration_configuration === "FIXED") &&
                                            (
                                                <InfiniteAddItems name="desserts" fields={fields} values={formData.desserts} onChange={handleInfiniteAdd} title='Dessert' requestChange={data} />
                                            )
                                        }
                                        <div className="d-flex justify-content-around">
                                            {
                                                showUpdate ?
                                                    <button className="bold-btn bold-btn-dark" onClick={handleUpdate}>{t('update')}</button>
                                                    :
                                                    <button className="bold-btn bold-btn-dark" onClick={handleSubmit}>{t('submit')}</button>
                                            }
                                        </div>
                                    </FormControl>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    )
}

export default DessertsConfiguration;