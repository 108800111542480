import { TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useForm } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import BoldFilesUploadProfile from "../../../utils/BoldFilesUploadProfile";
import InputMask from 'react-input-mask';
import imageNotFound from "../../../assets/img/image-not-found.webp";
import BoldInputPhone from "../../atoms/Inputs/BoldInputPhone";
import { LocationContext } from "../../../context/LocationContext";

const CompaniesForms = () => {

    const endpoint = "administration/companies";
    const parent = "Company";
    const [t] = useTranslation("global");
    const [title, setTitle] = useState('companyComponent.createCompany');
    const [description, setDescription] = useState('');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();

    const { id } = useParams();
    const [images, setImages] = useState([]);
    const [identificationType, setIdentificationType] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [formData, setFormData] = useState({
        company_name: "",
        company_dba: "",
        company_logo: "",
        company_identification_type: "",
        company_time_zone: "",
        company_identification: "",
        company_website: "",
        company_phone: "",
        indicativo: "",
        company_ext: ""
    });

    const { getCompanies } = useContext(LocationContext);

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        setTitle(t("companyComponent.createCompany"));
        setDescription(t("description.create_company"));
        if (id !== undefined) {
            setTitle(t('companyComponent.updateCompany'));
            setDescription(t("description.edit_company"));
        }
    }, [t])

    const getParamsUpdate = () => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
        .then(response => {
            if (response.success) {
                setIdentificationType(handlerApp.getResult(response.data, "identification_type"));
                setTimeZones(handlerApp.getResult(response.data, "time_zone"));
                if (id !== undefined) {
                    setTitle('companyComponent.updateCompany');
                    sendRequest();
                }
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response)
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    useEffect(() => {
        getParamsUpdate()
    }, [])

    const handleImages = (e) => {
        setImages(e)
    }

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }
    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });

        setValue(key, e.target.value);

        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];

                    if (data.company_logo == null) {
                        data.company_logo = imageNotFound;
                    }
                    setData(response.data);
                    setDisableButton(false)
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.showError(response.message);
                    setDisableButton(true)
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handlePhone = (value, name)=>{
        setValue(name,value);
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...data, 
            company_logo: images
        }
        api.post(endpoint, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                getCompanies();
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...data,
            company_logo: images
        }
        api.put(endpoint + "/" + id, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }


    const handleKeyDow = (event) => {
        // Verifica si la tecla presionada es Enter
        if (event.key === 'Enter') {
            // Evita el comportamiento predeterminado del formulario
            event.preventDefault();
        }
    };

    // const optionsArray = Object.entries(identificatioType).map(([code, name]) => ({ code, name }));
    // const optionsArrayZone = Object.entries(timeZones).map(([code, name]) => ({ code, name }));

    return (
        <div className="app container">
            <div className="title-section-forms">
                {t(title)}
                <p>{description}</p>
            </div>
            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)} noValidate onKeyDown={handleKeyDow}>
                    <div className="row">

                        <BoldFilesUploadProfile returnImages={handleImages} multiple={false} value={formData.company_logo} name={formData.company_name.substring(0, 2)} />

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t("name") + " *"} variant="standard" size="small"
                                {...register("company_name", { required: true, maxLength: 120 })}
                                value={formData.company_name ?? ''}
                                onChange={handleForm}
                                error={errors.company_name != null}
                                helperText={
                                    errors.company_name?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.company_name?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 120  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('DBA') + " *"}
                                variant="standard"
                                size="small"
                                {...register("company_dba", { required: true, maxLength: 60 })}
                                value={formData.company_dba ?? ''}
                                onChange={handleForm}
                                error={errors.company_dba != null}
                                helperText={
                                    errors.company_dba?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.company_dba?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 60  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('identificationType') + " *"}
                                value={formData.company_identification_type ?? ''}
                                name="company_identification_type"
                                onChange={handleForm}
                                options={identificationType}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('identification') + " *"} variant="standard" size="small"
                                {...register("company_identification", { required: true, maxLength: 30 })}
                                value={formData.company_identification ?? ''}
                                onChange={handleForm}
                                error={errors.company_identification != null}
                                helperText={
                                    errors.company_identification?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.company_identification?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 30  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />

                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('website')} variant="standard" size="small"
                                {...register("company_website", { maxLength: 120 })}
                                value={formData.company_website ?? ''}
                                onChange={handleForm}
                                error={errors.company_website != null}
                                helperText={
                                    errors.company_website?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 120 ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-4 mb-2">
                            <BoldInputPhone
                                name={"company_phone"}
                                label={t('phone')}
                                register={register}
                                errors={errors.company_phone}
                                onChange={handlePhone}
                                data={formData.company_phone}
                            />
                        </div>

                        <div className="col-md-2 mb-2">
                            <InputMask
                                mask={"9999"}
                                alwaysShowMask={false}
                                maskChar=" "
                                name="company_ext"
                                onChange={handleForm}
                                value={formData.company_ext ?? ''}
                                id="company_ext"
                                errors={errors}
                                required={false}
                            >
                                {() => <TextField
                                    name="company_ext"
                                    onChange={handleForm}
                                    value={formData.company_ext ?? ''}
                                    variant="standard"
                                    size="small"
                                    type="text"
                                    label={t('ext')}
                                />}
                            </InputMask>
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={"Time Zone *"}
                                value={formData.company_time_zone ?? ''}
                                name="company_time_zone"
                                onChange={handleForm}
                                options={timeZones}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && !disableButton && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CompaniesForms;