import HandlerApp from "../../../utils/handlerApp";
import DataTable from "react-data-table-component";
import NoDataTables from "../../noDataTable/NoDataTables";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { LocationContext } from "../../../context/LocationContext";

const CloseCashierHistory = () => {
    const endpoint   = "/close/cashier"; 
    const [t, i18n]  = useTranslation("global");
    const title      = t('closeCashierScreen.historyCloseCashier');
    const api        = useFetch();
    const nav        = useNavigate();
    const handlerApp = HandlerApp();
    const profile    = handlerApp.getProfile()
    
    // Permisos
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState([]);
    
    const { app, modeTheme } = useContext(AppContext);
    const { location, company, setLocation, setCompany,setLabelCompany, } = useContext(LocationContext);


    const formatDate = (datetime) => {
        const date = new Date(datetime);
        const options = { month: 'short', day: '2-digit', year: 'numeric' };
        const locale = i18n.language === 'es' ? 'es-ES' : 'en-US';
        return new Intl.DateTimeFormat(locale, options).format(date).replace(/ de /g, ', ');
    };
            
    const formatTime = (datetime) => {
        const date = new Date(datetime);
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
        const locale = i18n.language === 'es' ? 'es-ES' : 'en-US';
        return new Intl.DateTimeFormat(locale, options).format(date);
    };

    useEffect(() => {
        setData([])
        sendRequest();
    }, [location, company]);

    useEffect(() => {
        setPermissions(app.permissions[endpoint]);
    }, [app]);

    const handlerAction = (id, action,row = null) => {
        switch (action) {
            case 'view':
                if (row != null) {
                    setCompany(row.company_id)
                    setLabelCompany(row.company_id)
                    setLocation(row.location_id)
                    localStorage.setItem("currentLocation", JSON.stringify(row.location_id));
                }
                nav(`${endpoint}/view/${id}`);
                break;
            case 'refresh':
                sendRequest();
                break;
        }
    };

   

    const sendRequest = () => {
        setData([]);
        setLoading(true);
        api.get(endpoint, { limitOne: false, company, location  })
            .then(response => {
                if (response.success) {
                    setData(response.data);
                } else {
                    if (response.message !== "M001") {
                        handlerApp.handlerResponse(response);
                    }
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const translateCloseCashierType = (type) => {
        return type ? t('closeCashierScreen.manualClosure') : t('closeCashierScreen.automaticClosing');
    };

    const columns = [
        ...(profile <= 2 ? [
            {
                id: 'company_name',
                name: t('posComponent.company'),
                selector: row => row.company_name,
                sortable: true
            }
        ] : []),
        {
            id: 'location_name',
            name: t('locationComponent.locationName'),
            selector: row => row.location_name,
            sortable: true,
        },
        {
            id: 'fecha',
            name: t('closeCashierScreen.date'),
            selector: row => formatDate(row.closingcasher_datetime),
            sortable: true,
        },
        {
            id: 'hora',
            name: t('closeCashierScreen.hour'),
            selector: row => formatTime(row.closingcasher_datetime),
            sortable: true,
        },
        {
            id: 'closingcasher_type',
            name: t('closeCashierScreen.type'),
            selector: row => row.closingcasher_type,
            sortable: true,
            cell: row => {
                return translateCloseCashierType(row.closingcasher_type);
            }
        },
        {
            id: 'encargado',
            name: t('closeCashierScreen.encargado'),
            selector: row => row.created_by,
            sortable: true,
        },
        {
            id: 'total_ingresos',
            name: t('closeCashierScreen.totalIngresos'),
            selector: row => handlerApp.formatCurrency(row.closingcasher_total),
            sortable: true,
        },
        {
            name: t('action'),
            cell: (row) => { 
                return handlerApp.actions(row, permissions, 'view', () => handlerAction(row.closingcasher_id, 'view', row)); 
            },
            ignoreRowClick: true,
            button: true.toString(),
            minwidth: "160px"
        }
    ];

    // filtros barra de busqueda.
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => (item.closingcasher_debit && item.closingcasher_debit?.toLowerCase().includes(filterText.toLowerCase()))
            || item.created_by?.toString().toLowerCase().includes(filterText.toLowerCase())
            || formatDate(item.closingcasher_datetime).toLowerCase().includes(filterText.toLowerCase())
            || formatTime(item.closingcasher_datetime).toLowerCase().includes(filterText.toLowerCase())
            || item.closingcasher_type?.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.closingcasher_id?.toString().toLowerCase().includes(filterText.toLowerCase())
            || translateCloseCashierType(item.closingcasher_type).toLowerCase().includes(filterText.toLowerCase())
            || item.closingcasher_total?.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.company_name?.toLowerCase().includes(filterText.toLowerCase())

    );

    return (
        <div className="app container">
            <div className="title-section-forms">
                    <h3>{title}</h3>
                    <div className="d-flex">
                        <p className="text-details">{t('closeCashierScreen.historyDescription')}</p>
                    </div>
            </div>
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t('search')}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            <button className="btn-table-actions" onClick={() => { handlerAction(null, 'refresh') }}>
                                <span className="material-symbols-outlined">autorenew</span>
                            </button>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        noDataComponent={<NoDataTables />}
                        progressPending={handlerApp.setLoader(loading)}
                        theme={modeTheme ? "solarized" : ""}
                    />
                </div>
            </div>
        </div>
    );
};

export default CloseCashierHistory;