import moment from "moment";
import Swal from "sweetalert2";
import HandlerApp from "../../../utils/handlerApp";
import BoldSelect from "../../../utils/BoldSelect";
import ChangePasswordModal from "./ChangePasswordModal";
import BoldInputPhone from "../../atoms/Inputs/BoldInputPhone";
import BoldFilesUploadProfile from "../../../utils/BoldFilesUploadProfile";
import { Button } from "react-bootstrap";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { where } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";
import { Logout } from "../../../utils/BoldLogin";
import { useFetch } from "../../../hooks/useFecth";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { useFirebase } from "../../../hooks/useFirebase";
import { useNavigate, useParams } from "react-router-dom";
import { ValidatePassword } from "../../molecules/ValidatePassword";

const UserProfileForm = () => {

    const endpoint          = "configuration/profile";
    const [t, i18n]         = useTranslation('global');
    const [title, setTitle] = useState("");
    const firebase          = useFirebase();
    const logout            = Logout();
    // Permisos
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const { app,modeTheme } = useContext(AppContext);

    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app]);

    useEffect(() => {
        setTitle(t('myAccount'));
    }, [t]);

    // utils
    const api = useFetch();
    const nav = useNavigate();
    const { id } = useParams();
    // data
    const [data, setData] = useState([]);
    // estado inicial de formulario
    const [formData, setFormData] = useState({
        person_firstname: "",
        person_secondname: "",
        person_surname: "",
        person_secondsurname: "",
        person_sex: "",
        person_image: "",
        person_email: "",
        person_phone: "",
        person_ext: "",
        person_occupation: "",
        person_marital_status: "",
        person_birthdate: null
    });
    // estado del cambio de contraseña
    const [formPassword, setFormPassword] = useState({
        password: '',
        repeatPassword: ''
    });
    const [visibilityPassword, setVisibilityPassword] = useState({
        password: '',
        repeatPassword: ''
    })
    const [passwordError, setPasswordError] = useState('');
    const [repeatPasswordError, setRepeatPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState({
        password: true,
        repeatPassword: true
    });

    const [images, setImages] = useState([]);
    const handleImages = (e) => {
        setImages(e)
    }

    const [maritalStatusList, setMaritalStatusList] = useState([]);
    const [maritalStatusFilterList, setMaritalStatusFilterList] = useState([]);
    const [sexList, setSexList] = useState([]);
    const [sexFilterList, setSexFilterList] = useState([]);
    const [birthdate, setBirthdate] = useState(null);
    const { register, formState: { errors }, setValue, handleSubmit, control } = useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        if (d.person_birthdate != null) {
            setBirthdate(moment(d.person_birthdate));
            d.person_birthdate = moment(d.person_birthdate)
        }
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            if (data[key] === undefined || data[key] === null) {
                finalData[key] = "";
            } else {
                finalData[key] = data[key];
            }
            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    setData(response.data);
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
            .then(response => {
                if (response.success) {
                    setSexList(handlerApp.getResultFromObject(response.data, 'sex'));
                    setMaritalStatusList(handlerApp.getResultFromObject(response.data, 'maritalstatus'));
                    sendRequest();
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }, [])

    useEffect(() => {
        if(sexList.length > 0) {
            if(i18n.language =="en"){
                const nameSex = sexList.map(item => ({
                    code: item.code,
                    name: item.name,
                  }));
                  setSexFilterList(nameSex)
            }
            else {
                const nameSex = sexList.map(item => {
                    return {
                      code: item.code,
                      name: item.namespanish,
                    };
                  });
                  setSexFilterList(nameSex)
            }
        }
    }, [sexList, i18n.language])

    useEffect(() => {
        if(maritalStatusList.length > 0) {
            if(i18n.language =="en"){
                const nameMarital = maritalStatusList.map(item => ({
                    code: item.code,
                    name: item.name,
                  }));
                  setMaritalStatusFilterList(nameMarital)
            }
            else {
                const nameMarital = maritalStatusList.map(item => {
                    return {
                      code: item.code,
                      name: item.namespanish,
                    };
                  });
                  setMaritalStatusFilterList(nameMarital)
            }
        }
    }, [maritalStatusList, i18n.language])

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });

        setValue(key, e.target.value);

        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handlePhone = (value, name) => {
        setValue(name, value);
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const onSubmit = (e) => {
        e.preventDefault();
        handlerApp.setLoader(true);
        let body = {
            ...formData,
            person_image: images,
        }
        api.post(endpoint, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }

            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    useEffect(() => {
        if (birthdate?.hasOwnProperty('_d')) {
            setFormData({ ...formData, person_birthdate: birthdate?.format('YYYY-MM-DD') });
        }
    }, [birthdate]);

    const handleUpdate = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...data,
            person_image: images,
        }
        if (body.person_birthdate === "Invalid date") {
            body.person_birthdate = null;
        }

        api.put(endpoint + "/" + id, body, {
            action: 'personal_information'
        })
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav(`/configuration/profile/${id}`);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }


    const handleUpdatePassword = (e, name = null, dependency = null) => {
        e.preventDefault();
        if (passwordError != '') {
            handleCloseModal();
            Swal.fire({
                title: '',
                text: t('userRegister.passwordDiff'),
                icon: 'error',
                reverseButtons: true,
                confirmButtonText: 'OK',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    setIsModalOpen(true);
                }
            });

            return false;
        }
        handlerApp.setLoader(true);
        handleCloseModal();
        api.put(endpoint + "/" + id, {
            ...formPassword,
            action: 'password'
        })
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    localStorage.setItem('action', null);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }

            });
    }

    const handleFormPassword = (e) => {
        const { name, value } = e.target;
        const patternPassword = /^(?=.*\d)(?=.*[a-záéíóúüñ])(?=.*[A-ZÁÉÍÓÚÜÑ])(?=.*[\W_]).{8,80}$/; //patrón de validación contraseñas

        setVisibilityPassword({
            ...visibilityPassword,
            [name]: value
        }); // Guarda la contraseña sin codificar

        setFormPassword((prev) => ({ ...prev, [name]: handlerApp.encode(value) })); // Guarda la contraseña codificada
        // Comprobando si las contraseñas coinciden
        if (!value.match(patternPassword)) {
            setPasswordError(<ValidatePassword passwordValue={value}/>);
        } else if (name === 'password' && value !== visibilityPassword.repeatPassword) {  // Muestra mensaje de error si las contraseñas no coinciden
            setPasswordError(t("userRegister.passwordDiff"));
            setRepeatPasswordError(t("userRegister.passwordDiff"));
        } else if (name === 'repeatPassword' && value !== visibilityPassword.password) {  // Muestra mensaje de error si las contraseñas no coinciden
            setPasswordError(t("userRegister.passwordDiff"));
            setRepeatPasswordError(t("userRegister.passwordDiff"));
        } else{     // resetea el mensaje de error si las contraseñas coinciden
            setPasswordError(''); 
            setRepeatPasswordError('');
        }
    };

    const handleClickShowPassword = (key) => {
        setShowPassword(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    }

    const handleKeyDow = (event) => {
        // Verifica si la tecla presionada es Enter
        if (event.key === 'Enter') {
            // Evita el comportamiento predeterminado del formulario
            event.preventDefault();
        }
    };

    const cancelButtonColor = modeTheme ? "#32326c" : "";
    
    const handleLogout = () => {
        Swal.fire({
            icon: 'info',
            title: `${t('closeSessions.modalTitle')}`,
            text: `${t('closeSessions.modalBody')}`,
            showDenyButton: false,
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: `${t('cancel')}`,
            cancelButtonColor: cancelButtonColor,
            confirmButtonText: `${t('myAccounts.confirm')}`,
            confirmButtonColor: "#e50000"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const username = localStorage.getItem("username");
                firebase.get('token_users', null, where("user_email", "==", username))
                    .then(response => {
                        if (response.success) {
                            const sesionesOn = response.data;
                            const updatePromises = sesionesOn.map(sesion => firebase.deleteMethod('token_users', sesion.id)
                            );
                            Promise.all(updatePromises)
                                .then(() => {
                                    logout.closeSession();
                                })
                                .catch(error => {
                                    handlerApp.handlerResponse('E002')
                                });
                        } else {
                            handlerApp.handlerResponse('E002');
                        }
                    });
            }
        });
    };



    const handleCloseModal = () => {
        setVisibilityPassword({
            password: '',
            repeatPassword: ''
        });
        setPasswordError('');
        setIsModalOpen(false);
    };
    return (
        <div className="row d-flex" style={{ width: '95%', height: "100%" }}>
            <div className="title-section-forms d-flex justify-content-start ms-5">
                {title}
            </div>
            <div className="section-forms mb-5 col-xl-8 col-12">
                <form onSubmit={handleSubmit(handleUpdate)} onKeyDown={handleKeyDow}>
                    <div className="row">
                        <div className="col-md-12 form-username">
                            <BoldFilesUploadProfile
                                returnImages={handleImages}
                                multiple={false}
                                value={formData.person_image}
                                name={formData.person_firstname.substring(0, 2)}
                            />
                            <label>{data[0]?.user_username}</label>
                        </div>

                        <div className="col-md-12 row">
                            <div className="col-md-6 mb-2">
                                <TextField
                                    name="person_firstname"
                                    label={t('personComponent.firstName') + '*'}
                                    variant="standard"
                                    size="small"
                                    {...register("person_firstname", { required: true })}
                                    onChange={handleForm}
                                    error={errors.person_firstname != null}
                                    value={formData.person_firstname ?? ''}
                                    helperText={
                                        errors.person_firstname?.type === 'required'
                                            ? t('validationErrorMessages.required') : ""
                                    }
                                />
                            </div>

                            <div className="col-md-6 mb-2">
                                <TextField
                                    name="person_surname"
                                    label={t('personComponent.surName') + '*'}
                                    variant="standard"
                                    size="small"
                                    {...register("person_surname", { required: true })}
                                    onChange={handleForm}
                                    error={errors.person_surname != null}
                                    value={formData.person_surname ?? ''}
                                    helperText={
                                        errors.person_surname?.type === 'required'
                                            ? t('validationErrorMessages.required') : ""
                                    }
                                />
                            </div>

                            <div className="col-md-6 mb-2">
                                <TextField
                                    name="person_secondname"
                                    onChange={handleForm}
                                    value={formData.person_secondname ?? ''}
                                    label={t('personComponent.secondName')}
                                    variant="standard"
                                    size="small"
                                />
                            </div>

                            <div className="col-md-6 mb-2">
                                <TextField
                                    name="person_secondsurname"
                                    onChange={handleForm}
                                    value={formData.person_secondsurname ?? ''}
                                    label={t('personComponent.secondSurname')}
                                    variant="standard"
                                    size="small"
                                />
                            </div>

                            <div className="col-md-12 mb-2">
                                <TextField
                                    name="person_email"
                                    label={t('email') + '*'}
                                    variant="standard"
                                    size="small"
                                    {...register("person_email", { required: true })}
                                    onChange={handleForm}
                                    error={errors.person_email != null}
                                    value={formData.person_email ?? ''}
                                    helperText={
                                        errors.person_email?.type === 'required'
                                            ? t('validationErrorMessages.required') : ""
                                    }
                                />
                            </div>

                            <div className="col-md-6 mb-2">
                                <BoldInputPhone
                                    name={"person_phone"}
                                    label={t('phone') + "*"}
                                    register={register}
                                    errors={errors.person_phone}
                                    onChange={handlePhone}
                                    data={formData.person_phone}
                                />
                            </div>

                            <div className="col-md-6 mb-2">
                                <TextField
                                    name="person_ext"
                                    onChange={handleForm}
                                    value={formData.person_ext ?? ''}
                                    label={t('ext')}
                                    variant="standard"
                                    size="small"
                                />
                            </div>

                            <div className="col-md-6 mb-2">
                                <DatePicker
                                    name="person_birthdate"
                                    label={t('personComponent.birthdate')}
                                    format="YYYY/MM/DD"
                                    variant="standard"
                                    onChange={(val) => { setBirthdate(val) }}
                                    value={formData.person_birthdate ?? ''}
                                    disableFuture={true}
                                    slotProps={{ textField: { size: 'small', variant: "standard" } }}
                                />
                            </div>

                            <div className="col-md-6 mb-2">
                                <TextField
                                    name="person_occupation"
                                    label={t('occupation')}
                                    variant="standard"
                                    size="small"
                                    onChange={handleForm}
                                    value={formData.person_occupation ?? ''}
                                />
                            </div>

                            <div className="col-md-6 mb-2">
                                <BoldSelect
                                    name="person_marital_status"
                                    title={t('maritalstatus')}
                                    onChange={handleForm}
                                    value={formData.person_marital_status ?? ''}
                                    options={maritalStatusFilterList}
                                />
                            </div>

                            <div className="col-md-6 mb-2">
                                <BoldSelect
                                    name="person_sex"
                                    title={t('personComponent.sex')}
                                    onChange={handleForm}
                                    value={formData.person_sex ?? ''}
                                    options={sexFilterList}
                                />
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel}>{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" onClick={handleSubmit(onSubmit)}>{t('submit')}</button>}
                                <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>
                            </div>
                        </div>
                    </div>
                </form>
                <ChangePasswordModal
                    isOpen={isModalOpen}
                    handleClose={handleCloseModal}
                    handleUpdatePassword={handleUpdatePassword}
                    handleFormPassword={handleFormPassword}
                    visibilityPassword={visibilityPassword}
                    showPassword={showPassword}
                    passwordError={passwordError}
                    repeatPasswordError={repeatPasswordError}
                    handleClickShowPassword={handleClickShowPassword}
                />
            </div>

            <div className="col-xl-3 d-flex flex-column">
                <div className="section-forms mb-3">
                    <div className="title-section-forms d-flex justify-content-start mb-3">
                        {t("closeSessions.title")}
                    </div>
                    <div className="mb-2 color-white-txt">
                        {t("closeSessions.body1")}
                    </div>
                    <div className="mb-5 color-white-txt">
                        {t("closeSessions.body2")}
                    </div>
                    <div className="d-flex justify-content-center ">
                        <Button className="bold-btn bold-btn-dark" onClick={handleLogout}>{t("closeSessions.button")}</Button>
                    </div>
                </div>
                <div className="section-forms">
                    <div className="mb-3 color-white-txt">
                        {t("newPassword.body")}
                    </div>
                    <div className="d-flex justify-content-center">
                        <button className="bold-btn bold-btn-dark" onClick={() => setIsModalOpen(true)}>{t('changePassword')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserProfileForm;
