import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { LoaderContext } from "../context/LoaderContext";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { createTheme } from "react-data-table-component";

const HandlerApp= ()=>{
 
    const {app,setApp} = useContext(AppContext);
    const {loader,setLoaderInner} = useContext(LoaderContext);
    const [t, i18n] = useTranslation("global");
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        setPermissions(app.permissions);
    }, [app])


    const setLoader = (value) => {
        setLoaderInner(value);
    }


    const getLocation = () => {
        return localStorage.getItem('currentLocation');
    }
    const showAlertLocation = () => {
        Swal.fire({
            title: '',
            text: t('locationSelect'),
            icon: 'warning'
        });
    }

    const showAlertCompany = () => {
        Swal.fire({
            title: '',
            text: t('companySelect'),
            icon: 'warning'
        });
    }

 
    const getResultFromObject = (data, key) => {
        let variable = JSON.parse((data[0][key]));
       
        return variable;
    }

    const getResult = (data, key) => {
        let variable = JSON.parse((data[0][key]));
        let arrayAux = [];
        for (let i in variable) {
            let cod = i;
            if (isNaN(parseInt(cod))) { 
                arrayAux.push({
                    code: i,
                    name: variable[i]
                })
            } else {
                arrayAux.push({
                    code: parseInt(i),
                    name: variable[i]
                })
            }
        }
        return arrayAux;
    }

    const actions = (row, permissions, prefix, action) => {
        if (permissions) {
            return (
                <div className="d-flex justify-content-start w-100 p-1">
                    {permissions.indexOf('view') > -1 &&
                        <div>
                            <button className="btn-table-actions view" title="view" onClick={(e) => { action(row[prefix + "_id"], 'view') } } id={row.ID}>
                                <span className="material-symbols-outlined">visibility</span>
                            </button>
                        </div>}
                    {permissions.indexOf('update') > -1 &&
                        <div>
                            <button className="btn-table-actions update" title="edit" onClick={(e) => { action(row[prefix + "_id"], 'update') }} id={row.ID}>
                                <span className="material-symbols-outlined">edit</span>
                            </button>
                        </div>}
                    {permissions.indexOf('delete') > -1 &&
                        <div>
                            <button className="btn-table-actions delete" title="delete" onClick={(e) => { action(row[prefix + "_id"], 'delete') }} id={row.ID}>
                                <span className="material-symbols-outlined">delete</span>
                            </button>
                        </div>}
                    {permissions.indexOf('inactive') > -1 &&
                        <div>
                            {row[prefix + "_active"] === 1 &&
                                <button className="btn-table-actions inactive" title="active" onClick={(e) => { action(row[prefix + "_id"], 'inactive') }} id={row.ID}>
                                    <span className="material-symbols-outlined active">toggle_on</span>
                                </button>
                            }
                            {row[prefix + "_active"] === 0 &&
                                <button className="btn-table-actions inactive" title="inactive" onClick={(e) => { action(row[prefix + "_id"], 'active') }} id={row.ID}>
                                    <span className="material-symbols-outlined inactive">toggle_off</span>
                                </button>
                            }
                        </div>
                    }
                </div>
            )
        }

    }

    const handlerResponse = (response) => {
        return Swal.fire({
            title: '',
            text: t(response.message),
            icon: 'warning'
        });
    }

    const handlerValidation = (errors) => {
        const alertMessage = `
        <div style="font-size: 14px;">
    
            <p style="color: #000080; margin-bottom:20ppx;">${errors.length > 1 ? t('validationErrorMessages.pluralHeader') : t('validationErrorMessages.singleHeader')}</p>
    
            <ul style="list-style: none; margin-bottom: 20px;">
    
                ${errors.map((error) => `<li style="text-decoration: none; margin-bottom: 16px; ">${error}</li>`).join('')}
            </ul>
        </div>`;
    
        Swal.fire({
            title: '',
            html: alertMessage,
            icon: 'info',
            customClass: {
                title: 'small-font' 
            }
        });
    };
      
      
    const showOk = (response) => {
        Swal.fire({
            title: '',
            text: t(response.message),
            icon: 'success'
        });
    }

    const showError = (msg = null) => {
        Swal.fire({
            title: '',
            text: (msg === null) ? t("E002") : t(msg),
            icon: 'error'
        })
    }

    const showErrorWithData = (msg = null,uuid = null) => {
        const message = (msg === null) ? t("E002") : t(msg);
        const fullMessage = uuid ? `${message} (${uuid})` : message;
        Swal.fire({
            title: '',
            text: fullMessage,
            icon: 'error'
        });
    }

    const getPermissions = (value) => {
        return [];
    }

    const getDate = (date) => {
        let localTime = new Date(date);
        return localTime.toLocaleString("en-US", { hour12: true, year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" });
    }

    const formatMinutesToTime = (minutes) => {
        if (typeof minutes !== 'number' || isNaN(minutes) || minutes < 0) {
            return "Invalid input";
        }
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        const formattedTime = `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`;
        return formattedTime;
    };

    const formatCurrency = (value) => {
        let formatting_options = {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        }
        let dollarString = new Intl.NumberFormat("en-US", formatting_options);
        return dollarString.format(value);
    }
    const formatPercent = (value) => {
        let formatting_options = {
            style: 'percent',
            minimumFractionDigits: 2,
        }
        let num = new Intl.NumberFormat("en-US", formatting_options);
        return num.format(value / 100);
    }

    const encode = (str) => {
        let encoded = btoa(btoa(str));
        return encoded;
    }

    const decode = (str) => {
        let decoded = atob((atob(str)));
        return decoded;
    }

    const getUser = () => {
        if (localStorage.getItem('numberInformation') !== null) {
            return decode(localStorage.getItem('numberInformation'))
        }
        return "";
    }

    const getProfile = () => {
        if (localStorage.getItem('profile') !== null) {
            return localStorage.getItem('profile')
        }
        return "";
    }

    const filterData = (data, formData) => {
        let finalData = {};
   
        if (!Array.isArray(data)) {
            for (let key in formData) {
                finalData[key] = data[key];
            }
            return finalData;
        } else {
            for (let key in formData) {
                let llave = formData[key];
            
                switch (typeof llave) {
                    case 'number':
                        finalData[key] = 0;
                    break;
                    case 'string':
                        finalData[key] = "";
                    break;
                    case 'boolean':
                        finalData[key] = false;
                    break;
                    case 'object':
                        if (llave == null) { 
                            finalData[key] = null;
                        } else if (Array.isArray(llave)) { 
                            finalData[key] = [];
                        } else { 
                            finalData[key] = {};
                        }
                    break;
                    default:
                        finalData[key] = "";
                    break;
                }
            }
            return finalData;
        }
    }

    const themeTables = createTheme('solarized', {
        text: {
            primary: '#ffffff',
            secondary: '#ffffff',
        },
        background: {
            default: '#20222a',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: 'gray',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
        data: {
            color: '#FFFFFF',
        },
        }, 'dark');

    return {
        setLoader,
        getPermissions,
        actions,
        getResult, 
        getResultFromObject,
        handlerResponse,
        handlerValidation,
        showError,
        showOk,
        getDate,
        formatMinutesToTime,
        formatCurrency,
        formatPercent,
        encode,
        decode,
        getLocation,
        showAlertLocation,
        showAlertCompany,
        getUser,
        filterData,
        themeTables,
        showErrorWithData,
        getProfile
    }
}

export default HandlerApp;