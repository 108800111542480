import { CircularProgress, InputAdornment, TextField, IconButton, Stepper, Step, StepButton, StepLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../assets/css/login.css"
import logo from "../../assets/img/bravus-azul-rojo.png";
import logoNegativo from "../../assets/img/Logo-principal-negativo.png";
import InputMask from 'react-input-mask';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import BoldSelectValidatedRegister from "../../utils/BoldSelectValidatedRegister";
import Swal from "sweetalert2";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BoldInputPhone from "../atoms/Inputs/BoldInputPhone";
import { ValidatePassword } from "../molecules/ValidatePassword";

const Register = () => {

    const [t, i18n] = useTranslation("global");
    const nav = useNavigate();
    const endpoint = "/configuration/users_register"

    const [optionsArray, setOptionsArray] = useState([])
    const [error, setError] = useState("");
    const [form, setForm] = useState('user');
    const [data, setData] = useState({
        person_phone: "",
        company_phone: "",
    });
    const [password, setPassword] = useState("");
    const [identificationType, setIdentificationType] = useState([]);
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("");
    const [states, setStates] = useState([]);
    const [state, setState] = useState("");
    const [cities, setCities] = useState([]);
    const [plans, setPlans] = useState([]);
    const [plan, setPlan] = useState({});
    const [loader, setLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [showCardSecurityCode, setShowCardSecurityCode] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        toggleDarkMode();
    }, []);

    const toggleDarkMode = (toggleMode = null) => {
        const htmlElement = document.documentElement;
        let darkMode = localStorage.getItem('darkMode');

        if(toggleMode){
            darkMode = darkMode === 'dark' ? '' : 'dark';
        }
        
        
        if (darkMode === 'dark') {
            htmlElement.classList.add('dark');
            setIsDarkMode(true);
            localStorage.setItem('darkMode', 'dark');
        } else if (darkMode === '') {
            htmlElement.classList.remove('dark');
            setIsDarkMode(false);
            localStorage.setItem('darkMode', '');
        } else {
            const themeUser = window.matchMedia('(prefers-color-scheme: dark)').matches;
            if (themeUser) {
                htmlElement.classList.add('dark');
                setIsDarkMode(true);
                localStorage.setItem('darkMode', 'dark');
            } else {
                htmlElement.classList.remove('dark');
                setIsDarkMode(false);
                localStorage.setItem('darkMode', '');
            }
        }
    }

    const [userForm, setUserForm] = useState({
        person_firstname: "",
        person_surname: "",
        person_email: "",
        person_password: "",
        person_phone: "",
        card_number: "",
        name: "",
        card_expiry: "",
        card_securitycode: "",
        card_zipcode: "",
        profile_id: 3,
        company_name: "",
        company_identification_type: "",
        company_id: "",
        company_phone: "",
        location_currency: 1,
        location_language: i18n.language == 'es' ? 2 : 1,
        location_country: "",
        location_state: "",
        location_city: "",
        location_adress: "",
        location_name: "",
        coupon_id: "",
        subscription_id: 0,
        subscription_price: 0
    });

    //modal de error
    const errorModal = (text = false) => {
        localStorage.clear();
        Swal.fire({
            title: "",
            text: text ? text : "Created failed",
            icon: "error"
        });
    }

    const CustomStepIcon = ({ active, completed, icon }) => {
        return isDarkMode ? (

            <div style={{
                color: completed ? 'white' : active ? 'white' : 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: 30,
                height: 30,
                backgroundColor: completed ? '#32326c' : active ? '#32326c' : '#da3452',
            }}>
                {completed ?
                    <span className="material-symbols-outlined">
                        check
                    </span>
                    : icon}
            </div>
        ) :
            (

                <div style={{
                    color: completed ? 'white' : active ? 'white' : 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    width: 30,
                    height: 30,
                    backgroundColor: completed ? '#1c223a' : active ? '#1c223a' : 'lightgray',
                }}>
                    {completed ?
                        <span className="material-symbols-outlined">
                            check
                        </span>
                        : icon}
                </div>
            );
    };

    const getPlans = () => {
        setLoader(true)
        instance.get('get_plans')
            .then((response) => {
                if (response.data.success) {
                    let plans = response.data.data.filter((sub) => sub.subscription_type.includes('LOCATION'))
                    setPlans(plans);
                    setLoader(false)
                } else {
                    setLoader(false)

                }
            })
            .catch((error) => {
                setLoader(false)

            });
    };

    const getCompanies = () => {
        instance.get('get_companies_type_id')
            .then(response => {
                if (response.data.success) {
                    setIdentificationType(getResultFromObject(response.data.data.typeId, "identification_type"));
                    setCountries(response.data.data.countries)
                    setLoader(false)
                } else {
                    setLoader(false)
                }
            })
            .catch(error => {
                setLoader(false)
            })
    }
    // countries
    const getState = () => {
        setLoader(true)
        if (country !== undefined && country !== "") {
            instance.get('get_states/' + country)
                .then((response) => {
                    if (response.data.success) {
                        setStates(response.data.data);
                        setLoader(false)
                    } else {
                        setLoader(false)

                    }
                })
                .catch((error) => {
                    setLoader(false)

                });
        }
    };

    const getCities = () => {
        setLoader(true)
        if (state !== undefined && state !== "") {
            instance.get('get_cities/' + country + '/' + state)
                .then((response) => {
                    if (response.data.success) {
                        setCities(response.data.data);
                        setLoader(false)
                    } else {
                        setLoader(false)
                    }
                })
                .catch((error) => {
                    setLoader(false)
                });
        }
    };


    const handleChangeData = (e) => {
        let key = e.target.name;
        let value = e.target.value;

        // Verificar si el nombre del campo es "card_expiry" o "card_securitycode"
        if (key === 'card_securitycode') {
            // Verificar si el valor ingresado contiene solo números\
            if (/^\d*$/.test(value)) {
                // Actualizar el estado del formulario con el nuevo valor
                setUserForm({
                    ...userForm,
                    [key]: value
                });
                setValue(key, value)
            }
        } else {
            // Actualizar el estado del formulario con el nuevo valor
            setUserForm({
                ...userForm,
                [key]: value
            });
            setValue(key, value)
        }

        if (key === 'location_country') {
            setCountry(value);
            setStates([]);
            setCities([]);
            getState();
        } else if (key === 'location_state') {
            setState(value);
            setCities([]);
        }
        setError("");
    }

    const handlePasswordChange = (event) => {
        setValue('person_passwordconfirm', event.target.value)
        setPassword(event.target.value);
    };

    const handleFormCompany = (datos) => {
        setLoader(true);
        let pass = btoa(unescape(encodeURIComponent(btoa(unescape(encodeURIComponent(datos.person_password))))))
        let cardExpiryValidation = datos.card_expiry.split('/'); // 'MM/YY' -> ['MM', 'YY']

        let monthExpiry = parseInt(cardExpiryValidation[0]); // Mes de la tarjeta
        let yearExpiry = parseInt('20' + cardExpiryValidation[1]); // Año de la tarjeta

        // Obtener el mes y año actual
        let currentDate = new Date();
        let currentMonth = currentDate.getMonth() + 1; // Los meses en JavaScript van de 0 (Enero) a 11 (Diciembre), por eso sumamos 1.
        let currentYear = currentDate.getFullYear(); // Año actual
        
        // Validación del mes
        if (monthExpiry > 12 || monthExpiry < 1) {
            setLoader(false);
            Swal.fire({
                title: '',
                text: t('E204'), // Mes de caducidad inválido
                icon: 'warning',
            });
            return;
        }

        // Validación del año y mes
        if (yearExpiry < currentYear || (yearExpiry === currentYear && monthExpiry < currentMonth)) {
            setLoader(false);
            Swal.fire({
                title: '',
                text: t('E218'), // La tarjeta ya ha expirado
                icon: 'warning',
            });
            return;
        }

        
        let cardExpiry = datos.card_expiry.replace('/', '');
        let cardNumber = datos.card_number.replace(/\s/g, "")
        let body = {
            ...datos,
            person_password: pass,
            card_number: cardNumber,
            card_expiry: cardExpiry,
            profile_id: 3,
            location_currency: 1,
            location_language: i18n.language === 'es' ? 2 : 1,
            subscription_id: plan.subscription_id,
            subscription_price: plan.subscription_price
        }
        instance.post(process.env.REACT_APP_URL_BACKEND + endpoint, body)
            .then(response => {
                if (response.data.success) {
                    setLoader(false)
                    Swal.fire({
                        title: '',
                        text: t('S001'),
                        icon: 'success',
                    });
                    returnLogin();
                    setLoader(false);
                } else {
                    userForm.person_password = password
                    setLoader(false)
                    Swal.fire({
                        title: '',
                        text: t(response.data.message),
                        icon: 'warning',
                    });
                }
            })
            .catch(error => {
                userForm.person_password = decodeURIComponent(atob(decodeURIComponent(atob(userForm.person_password))))
                setLoader(false)
                Swal.fire({
                    title: '',
                    text: t(error.response.data.message),
                    icon: 'warning',
                });
            })
    }

    const returnLogin = () => {
        nav('/login')
    }

    const getResultFromObject = (data, key) => {
        let variable = JSON.parse((data[0][key]));
        return variable;
    }

    const instance = axios.create({
        baseURL: process.env.REACT_APP_URL_BACKEND,
    })

    const handleClickShowPassword = (pass) => {
        if (pass === 'person_password') {
            setShowPassword(!showPassword);
        } else if (pass === 'person_passwordconfirm') {
            setShowPasswordConfirm(!showPasswordConfirm)
        } else if (pass === 'card_security_code') {
            setShowCardSecurityCode(!showCardSecurityCode)
        }
    };

    const handleValidateEmail = (datos) => {
        if (datos.person_phone !== "") {
            setData({
                ...data,
                "person_phone": datos.person_phone
            })
        }
        setLoader(true)
        let email = btoa(unescape(encodeURIComponent(btoa(unescape(encodeURIComponent(datos.person_email))))))
        instance.get('user/email/' + email)
            .then(response => {
                if (response.data.success) {
                    setActiveStep(1)
                    setLoader(false)

                } else {
                    Swal.fire({
                        title: t(('tutorial.warning')),
                        text: t(response.data.message),
                        icon: "error"
                    });
                    setLoader(false)
                    userForm.person_password = password;
                }
            })
            .catch(error => {
                setLoader(false)
            })
    }

    const handleValidateCompany = (datos) => {
        if (datos.company_phone !== "") {
            setData({
                ...data,
                "company_phone": datos.company_phone
            })
        }
        setLoader(true)
        let company = btoa(unescape(encodeURIComponent(btoa(unescape(encodeURIComponent(datos.company_name))))))
        instance.get('get-company/' + company)
            .then(response => {
                if (response.data.success) {
                    setActiveStep(2)
                    setLoader(false)
                } else {
                    Swal.fire({
                        title: t(('tutorial.warning')),
                        text: t(response.data.message),
                        icon: "error"
                    });
                    setLoader(false)
                }
            })
            .catch(error => {
                setLoader(false)
            })
    }

    const handlePhone = (value, name) => {
        setValue(name, value)
        setUserForm({
            ...userForm,
            [name]: value
        });
    };

    const handlePhoneC = (value, name) => {
        setValue(name, value)
        setUserForm({
            ...userForm,
            [name]: value
        });
    };

    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});

    const handleStep = (step) => {
        setActiveStep(step);
    }
    const completedStep = (index, value) => {
        if (value === null) {
            setCompleted({ ...completed, [index]: true })
        } else {
            setCompleted({ ...completed, [index]: value })
        }
    }

    useEffect(() => {
        setOptionsArray(Object.entries(identificationType).map(([code, name]) => ({ code, name })))
    }, [identificationType])

    useEffect(() => {
        if (password !== userForm.person_password) {
            setError(t("userRegister.passwordDiff"))
        } else {
            setError("")
        }
    }, [password])

    useEffect(() => {
        getCities();
    }, [state]);

    useEffect(() => {
        getState();
    }, [country]);

    useEffect(() => {
        plan.subscription_id && setUserForm({ ...userForm, subscription_id: plan.subscription_id, subscription_price: plan.subscription_price });
    }, [plan]);

    useEffect(() => {
        setLoader(true)
        getPlans();
        getCompanies();
    }, [])
    return (
        <div className="d-flex" style={{ height: '100vh' }}>
            <div className="d-flex justify-content-center contenedor-imagen registerImagen" style={{ overflow: 'hidden' }}></div>
            <div className="flex justify-content-center align-content-center registerContainerForm w-50" style={{ overflowY: 'auto' }}>
                <div className="dark-mode-toggle">
                    <button onClick={()=>toggleDarkMode(true)} className="">
                        {isDarkMode ? <><span className="material-symbols-outlined" >light_mode</span><p>{t('toggleDarkMode.Light')}</p></>
                        :<><span className="material-symbols-outlined" >dark_mode</span><p>{t('toggleDarkMode.Dark')}</p></>}    
                    </button>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center registerForm w-100">
                        {isDarkMode ? <img src={logoNegativo} alt="logo-brava" className="logo-register logo-r" /> :
                            <img src={logo} alt="logo-brava" className="logo-register logo-r" />}
                    <h1 className={`fs-6 fw-semibold text-body-secondary" ${isDarkMode && "text-white"}`}>{t('singup')}</h1>
                    <Stepper className="mb-3 m-3 w-75" activeStep={activeStep} alternativeLabel>
                        <Step key="1" completed={completed[0]} className="col-3">
                            <StepButton onClick={
                                () => {
                                    handleStep(0);
                                    completedStep(0, false);
                                }
                            }>
                                <StepLabel StepIconComponent={CustomStepIcon}>{activeStep == 0 && t('personComponent.personInformation')}</StepLabel>

                            </StepButton>
                        </Step>
                        <Step key="2" completed={completed[1]} className="col-3">
                            <StepButton onClick={
                                () => { handleStep(1) }}>
                                <StepLabel StepIconComponent={CustomStepIcon}>{activeStep == 1 && t('personComponent.businessInformation')}</StepLabel>
                            </StepButton>
                        </Step>
                        <Step key="3" className="col-3">
                            <StepButton onClick={
                                () => { handleStep(2) }}>
                                <StepLabel StepIconComponent={CustomStepIcon}>{activeStep == 2 && t('userRegister.choosePlan')}</StepLabel>
                            </StepButton>
                        </Step>
                        <Step key="4" className="col-3">
                            <StepButton onClick={
                                () => { handleStep(3) }}>
                                <StepLabel StepIconComponent={CustomStepIcon}> {activeStep == 3 && t('personComponent.paymentInformation')}</StepLabel>
                            </StepButton>
                        </Step>
                    </Stepper>
                    {activeStep === 0 &&
                        <div className="w-75 p-2">
                            <form onSubmit={handleSubmit(handleValidateEmail)} noValidate>
                                <div className="row justify-content-evenly mt-2">
                                    <div className="col-6">
                                        <TextField name="person_firstname" label={t("personComponent.firstName")}
                                            {...register("person_firstname", { required: true })}
                                            value={userForm.person_firstname}
                                            autoComplete="off"
                                            onChange={handleChangeData}
                                            error={errors.person_firstname != null}
                                            helperText={
                                                errors.person_firstname?.type === 'required'
                                                    ? t('validationErrorMessages.required') : ""
                                            }
                                            required variant="standard" size="small" />
                                    </div>
                                    <div className="col-6">
                                        <TextField name="person_surname" label={t("personComponent.surName")}
                                            {...register("person_surname", { required: true })}
                                            value={userForm.person_surname}
                                            autoComplete="off"
                                            onChange={handleChangeData}
                                            error={errors.person_surname != null}
                                            helperText={
                                                errors.person_surname?.type === 'required'
                                                    ? t('validationErrorMessages.required') : ""
                                            }
                                            required variant="standard" size="small" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <TextField type={"email"} name="person_email" label={t("email")}
                                        {...register('person_email', {
                                            required: true,
                                            maxLength: 80,
                                            pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/i, // Email format regex
                                        })}
                                        error={errors.person_email != null}
                                        helperText={
                                            errors.person_email?.type === 'required'
                                                ? t('validationErrorMessages.required')
                                                : errors.person_email?.type === 'maxLength'
                                                    ? `${t('validationErrorMessages.long')} 80 ${t('validationErrorMessages.longSuffix')}`
                                                    : errors.person_email?.type === 'pattern'
                                                        ? t('validationErrorMessages.invalidEmailFormat')
                                                        : ''
                                        }
                                        value={userForm.person_email}
                                        autoComplete="off"
                                        onChange={handleChangeData}
                                        required variant="standard" size="small" />
                                </div>
                                    <div className="row justify-content-evenly">
                                        <div className="col-12">
                                            <BoldInputPhone
                                                name={"person_phone"}
                                                label={t('phone')}
                                                register={register}
                                                errors={errors.person_phone}
                                                onChange={handlePhone}
                                                data={userForm.person_phone}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <TextField
                                                name="coupon_id"
                                                label={t('coupon')}
                                                value={userForm.coupon_id}
                                                autoComplete="off"
                                                onChange={handleChangeData}
                                                variant="standard"
                                                size="small"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 ">
                                        <TextField name="person_password" label={t('password')}
                                            {...register("person_password", {
                                                required: true,
                                                validate: (value) => value === password,
                                                pattern: /^(?=.*\d)(?=.*[a-záéíóúüñ])(?=.*[A-ZÁÉÍÓÚÜÑ])(?=.*[\W_]).{8,}$/, //Formato Regex para válidar que contenga mayúsculas, minúsculas, números, signos y minimo 8 caracteres
                                                maxLength: 80,
                                            })}
                                            error={errors.person_password != null}
                                            helperText={
                                                errors.person_password?.type === 'required'
                                                    ? t('validationErrorMessages.required') :
                                                    errors.person_password?.type === 'validate'
                                                        ? t("validationErrorMessages.dontMatchPassword") :
                                                        errors.person_password?.type === 'pattern'
                                                            ? <ValidatePassword passwordValue={userForm.person_password}/> :
                                                                errors.person_password?.type === 'maxLength'
                                                                ? t('validationErrorMessages.long')+ ' 80 ' + t('validationErrorMessages.longSuffix') : ""
                                            }
                                            value={userForm.person_password}
                                            autoComplete="off"
                                            onChange={handleChangeData}
                                            required variant="standard" size="small"
                                            type={showPassword ? "text" : "password"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => handleClickShowPassword('person_password')}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </div>
                                    
                                <div className="col-12">
                                    <TextField name="person_passwordconfirm" label={t('re-password')}
                                        {...register("person_passwordconfirm", {
                                            required: true,
                                            validate: (value) => value === userForm.person_password
                                        })}
                                        error={errors.person_passwordconfirm != null}
                                        helperText={
                                            errors.person_passwordconfirm?.type === 'required'
                                                ? t('validationErrorMessages.required') :
                                                errors.person_passwordconfirm?.type === 'validate'
                                                    ? t("validationErrorMessages.dontMatchPassword") : ""
                                        }
                                        value={password}
                                        autoComplete="off"
                                        onChange={handlePasswordChange}
                                        required variant="standard" size="small"
                                        type={showPasswordConfirm ? "text" : "password"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleClickShowPassword('person_passwordconfirm')}
                                                    >
                                                        {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} />
                                </div>
                                <div className="mt-4 d-flex justify-content-between mb-4">
                                    <center>
                                        <Button className="bold-btn-dark back" onClick={returnLogin}>{t('back')}</Button>
                                    </center>
                                    <center>
                                        <Button className="bold-btn-dark" type="submit">{t('next')}</Button>
                                    </center>
                                </div>
                            </form>
                        </div>
                    }
                    {activeStep === 1 &&
                        <div className="w-75 p-2 mt-4">
                            <label>
                                <p className={`${isDarkMode && "text-light"}`}>{t('userRegister.config')}</p>
                            </label>
                            <form onSubmit={handleSubmit(handleValidateCompany)} noValidate>
                                <div className="row justify-content-evenly mt-4">
                                    <div className="col-md-6 ">
                                        <TextField name="company_name" label={t('userRegister.companyName')}
                                            {...register("company_name", { required: true })}
                                            error={errors.company_name != null}
                                            helperText={
                                                errors.company_name?.type === 'required'
                                                    ? t('validationErrorMessages.required') : ""
                                            }
                                            value={userForm.company_name}
                                            onChange={handleChangeData}
                                            required variant="standard" size="small" />
                                    </div>
                                    <div className="col-md-6">
                                        <BoldInputPhone
                                            name={"company_phone"}
                                            label={t('phone')}
                                            register={register}
                                            errors={errors.company_phone}
                                            onChange={handlePhoneC}
                                            data={userForm.company_phone}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <BoldSelectValidatedRegister
                                            title={t('identificationType')}
                                            name="company_identification_type"
                                            options={optionsArray}
                                            value={userForm.company_identification_type}
                                            onChange={handleChangeData}
                                            register={register}
                                            errors={errors}
                                            required={true}
                                            message={t('validationErrorMessages.required')}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField name="company_id" label={t('userRegister.companyIdentification')}
                                            value={userForm.company_id}
                                            {...register("company_id", { required: true })}
                                            error={errors.company_id != null}
                                            helperText={
                                                errors.company_id?.type === 'required'
                                                    ? t('validationErrorMessages.required') : ""
                                            }
                                            onChange={handleChangeData}
                                            required variant="standard" size="small" />
                                    </div>
                                </div>
                                
                                <div className="row justify-content-evenly">
                                    <div className="register-column-6">
                                        <TextField name="location_name" label={t("locationComponent.locationName")}
                                            {...register("location_name", { required: true })}
                                            error={errors.location_name != null}
                                            helperText={
                                                errors.location_name?.type === 'required'
                                                    ? t('validationErrorMessages.required') : ""
                                            }
                                            value={userForm.location_name}
                                            onChange={handleChangeData}
                                            required variant="standard" size="small" />
                                    </div>
                                    <div className="register-column-6 ">
                                        <TextField name="location_adress" label={t('address')}
                                            {...register("location_adress", { required: true })}
                                            error={errors.location_adress != null}
                                            helperText={
                                                errors.location_adress?.type === 'required'
                                                    ? t('validationErrorMessages.required') : ""
                                            }
                                            value={userForm.location_adress}
                                            onChange={handleChangeData}
                                            required variant="standard" size="small" />
                                    </div>
                                </div>
                                <div className="row justify-content-evenly">
                                    <div className="register-column-4">
                                        <BoldSelectValidatedRegister
                                            title={t('country') + ' *'}
                                            name="location_country"
                                            options={countries}
                                            value={userForm.location_country}
                                            onChange={handleChangeData}
                                            register={register}
                                            errors={errors}
                                            required={true}
                                            message={t('validationErrorMessages.required')}
                                        />
                                    </div>
                                    <div className="register-column-4">
                                        <BoldSelectValidatedRegister
                                            title={t('state') + ' *'}
                                            name="location_state"
                                            options={states}
                                            value={userForm.location_state}
                                            onChange={handleChangeData}
                                            register={register}
                                            errors={errors}
                                            required={true}
                                            message={t('validationErrorMessages.required')}
                                        />
                                    </div>
                                    <div className="register-column-4">
                                        <BoldSelectValidatedRegister
                                            title={t('city') + ' *'}
                                            name="location_city"
                                            options={cities}
                                            value={userForm.locatilocation_cityon_state}
                                            onChange={handleChangeData}
                                            register={register}
                                            errors={errors}
                                            required={true}
                                            message={t('validationErrorMessages.required')}
                                        />
                                    </div>

                                </div>
                                <div className="mt-5 d-flex justify-content-between">
                                    <center>
                                        <Button className="bold-btn-dark back" onClick={() => setActiveStep(0)}>{t('back')}</Button>
                                    </center>
                                    <center>
                                        <Button className="bold-btn-dark" type="submit">{t('next')}</Button>
                                    </center>
                                </div>
                            </form>
                        </div>
                    }
                    {activeStep === 2 &&
                        <div className="w-75 p-2 mt-2">
                            <label className="col-12 mt-2 align-items-center">
                                <p className="register-subtitle-1">{t('userRegister.selectPlan')}</p>
                            </label>
                            <div className="col-md-12 mt-2 d-flex gap-3 justify-content-center">
                                {
                                    plans.map((sub) =>
                                        isDarkMode ?
                                            <Button className={sub.subscription_id == plan.subscription_id ?"plan-card-register select-card-dark":"plan-card-register not-select-card-dark"} onClick={() => setPlan(sub)}>
                                                <p className="title-section-sub medium-text regular mb-0 white" >{sub.subscription_name}</p>
                                                <div className="d-flex align-items-center">
                                                    <p className="bold white" style={{ fontSize: '30px', }}>${(sub.subscription_price / 100).toFixed(2)}</p>
                                                    <p className="light medium-text ms-2 white" >{`${t('myAccounts.for')} ${sub.subscription_life} ${t('myAccounts.days')}`}</p>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <span className="material-symbols-outlined check-plans medium-text" style={{ backgroundColor: 'white', color: (sub.subscription_id == plan.subscription_id) ? '#36366E' : '#5a5f72'}} >
                                                        check
                                                    </span>
                                                    <p className="light medium-text ms-2 white" >{sub.subscription_description}</p>
                                                </div>
                                            </Button> :
                                            <Button className={sub.subscription_id == plan.subscription_id ?"plan-card-register select-card":"plan-card-register not-select-card"} onClick={() => setPlan(sub)}>
                                                <p className="title-section-sub medium-text regular mb-0" style={{ color: (sub.subscription_id == plan.subscription_id) ? 'white' : '#202742' }}>{sub.subscription_name}</p>
                                                <div className="d-flex align-items-center">
                                                    <p className="bold" style={{ fontSize: '30px' }}>$ {(sub.subscription_price / 100).toFixed(2)}</p>
                                                    <p className="light medium-text ms-2">{`${t('myAccounts.for')} ${sub.subscription_life} ${t('myAccounts.days')}`}</p>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <span className={sub.subscription_id == plan.subscription_id ?"material-symbols-outlined check-plans medium-text selected-card" : "material-symbols-outlined check-plans medium-text not-selected-card" } >
                                                        check
                                                    </span>
                                                    <p className="light medium-text ms-2 white" >{sub.subscription_description}</p>
                                                </div>
                                            </Button>
                                    )
                                }
                            </div>

                            <div className="mt-4 d-flex justify-content-between">
                                <center>
                                    <Button className="bold-btn-dark back" onClick={() => setActiveStep(1)}>{t('back')}</Button>
                                </center>
                                <center>
                                    <Button className="bold-btn-dark" disabled={!plan.subscription_id} onClick={() => setActiveStep(3)}>{t('next')}</Button>
                                </center>
                            </div>
                        </div>
                    }
                    {activeStep === 3 &&
                        <div className="w-75 p-2 mt-2">
                            <form onSubmit={handleSubmit(handleFormCompany)} noValidate>
                                <div className="col-md-12 mt-2">
                                    <TextField name="card_number" label={t('userRegister.cardNumber')}
                                        value={userForm.card_number}
                                        {...register("card_number", { required: true })}
                                        error={errors.card_number != null}
                                        helperText={
                                            errors.card_number?.type === 'required'
                                                ? t('validationErrorMessages.required') : ""
                                        }
                                        onChange={handleChangeData}
                                        InputProps={{
                                            inputComponent: InputMask,
                                            inputProps: {
                                                mask: "9999 9999 9999 9999",
                                                maskChar: "",
                                            }
                                        }}
                                        required variant="standard" size="small" />
                                </div>
                                <div className="col-md-12">
                                    <TextField name="name" label={t('userRegister.cardName')}
                                        value={userForm.name}
                                        {...register("name", { required: true })}
                                        error={errors.name != null}
                                        helperText={
                                            errors.name?.type === 'required'
                                                ? t('validationErrorMessages.required') : ""
                                        }
                                        onChange={handleChangeData}
                                        required variant="standard" size="small" />
                                </div>
                                <div className="row justify-content-evenly align-items-center">
                                    <div className="col-6">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                name="card_expiry"
                                                label={t('userRegister.expirationDate') + " (MMYY)"}
                                                value={userForm.card_expiry}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <CalendarMonthIcon />
                                                        </InputAdornment>
                                                    ),
                                                    maxLength: 4,
                                                    inputComponent: InputMask,
                                                    inputProps: {
                                                        mask: "99/99",
                                                        maskChar: "",
                                                    }
                                                }}
                                                {...register("card_expiry", { required: true })}
                                                error={errors.card_expiry != null}
                                                helperText={
                                                    errors.card_expiry?.type === 'required'
                                                        ? t('validationErrorMessages.required') : ""
                                                }
                                                onChange={handleChangeData}
                                                required
                                                variant="standard"
                                                size="small"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField name="card_securitycode" label="CVV / CVC"
                                                {...register("card_securitycode", { required: true })}
                                                error={errors.person_passwordconfirm != null}
                                                helperText={
                                                    errors.card_securitycode?.type === 'required'
                                                        ? t('validationErrorMessages.required') : ""
                                                }
                                                value={userForm.card_securitycode}
                                                autoComplete="off"
                                                onChange={handleChangeData}
                                                required variant="standard" size="small"
                                                type="password"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <CreditCardIcon />
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: {
                                                        maxLength: 3,
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <TextField name="card_zipcode" label={t('zipCode')}
                                        value={userForm.card_zipcode}
                                        {...register("card_zipcode", { required: true })}
                                        error={errors.card_zipcode != null}
                                        helperText={
                                            errors.card_zipcode?.type === 'required'
                                                ? t('validationErrorMessages.required') : ""
                                        }
                                        onChange={handleChangeData}
                                        required variant="standard" size="medium" />
                                </div>
                                <div className="mt-4 d-flex justify-content-between">
                                    <center>
                                        <Button className="bold-btn-dark back" onClick={() => setActiveStep(2)}>{t('back')}</Button>
                                    </center>
                                    <center>
                                        <Button className="bold-btn-dark" type="submit">{t('submit')}</Button>
                                    </center>
                                </div>
                            </form>
                        </div>
                    }
                </div>
            </div>

            {
                loader &&
                <>
                    <div className="content-loader">
                        <CircularProgress />
                    </div>
                </>
            }
        </div>
    )
}

export default Register;