import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import imageNotFound from "../../../assets/img/image-not-found.webp";
import { useTranslation } from "react-i18next";


const PaymentProcessorsView = ()=>{
    const endpoint = "configuration/payment-processor";
    const [t,i18n] = useTranslation("global");
    const prefix = "paymentprocessor";
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data,setData] = useState({});
    const [title,setTitle] = useState("");
    const nav = useNavigate();

    const {id} = useParams();
    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setTitle(data.paymentprocessor_processor);
                setData(data);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    },[])

    const back = ()=>{
        nav(-1);
    }

    return(
        <div className="app">
            <div className="container bold-container-content2">
                <div className="d-flex align-items-center back-arrow">
                    <div className="d-flex row justify-content-between align-items-center arrow-back-icon2 ">
                        <span className="material-symbols-outlined arrowBack backArrow " role="button" onClick={back}>arrow_back</span>
                    </div>
                    <div style={{marginLeft: '10px'}}>{title}</div>
                </div>
            </div>
            <div className="container bold-container-content">
                <div className="row ">
                    {/* { (data.paymentprocessor_processor.toUpperCase() != "CLOVER") && 
                        <>
                        <div className="col-md-2 d-flex mb-4">
                            <section className="section-image-view" style={{backgroundImage: `url('${imageNotFound}')`}}>
                            </section>
                        </div>
                        </>
                    }
                    { data.paymentprocessor_processor.toUpperCase() == "CLOVER" &&
                        <>
                        <div className="col-md-2 d-flex mb-4">
                            <section className="section-image-view" style={{backgroundImage: `url('/img/clover-procesador-brava-business-solution.png')`}}>
                            </section>
                        </div>
                        </>
                    } */}
                    <div className="col-md-10 np-row">
                        <div className="row nm-row ">
                            <div className="col-md-6 mb-2">
                                <dt>{t('paymentProcessorComponent.processor')}</dt>
                                <dd>{data.paymentprocessor_processor}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('locationComponent.location')}</dt>
                                <dd>{data.location_name}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('paymentProcessorComponent.merchantId')}</dt>
                                <dd>{data.paymentprocessor_merchant_id}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('paymentProcessorComponent.appId')}</dt>
                                <dd>{data.paymentprocessor_app_id}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('paymentProcessorComponent.secretId')}</dt>
                                <dd>{data.paymentprocessor_secret_id}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('paymentProcessorComponent.token')}</dt>
                                <dd>{data.paymentprocessor_token}</dd>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6 mb-2">
                        <dt>{t('phone')}</dt>
                        { (data.company_ext !== undefined && data.company_ext !== null) && <dd>{data.company_phone + " - ext: " + data.company_ext}</dd>}
                        { (data.company_ext === undefined || data.company_ext === null) && <dd>{data.company_phone}</dd> }
                    </div> */}
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentProcessorsView;