import { useContext, useEffect, useRef, useState } from "react";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { LocationContext } from "../../../context/LocationContext";
import imageNotFound from "../../../assets/img/image-not-found.webp";
import Swal from "sweetalert2";
import NoDataTables from "../../noDataTable/NoDataTables";
import { Checkbox, TextField } from "@mui/material";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import { useForm } from "react-hook-form";
import EditTextCell from "./EditTextCell";

const ProductLocation2 = () => {
    const endpoint = 'configuration/locations/products';
    const api = useFetch();
    const [data, setData] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const nav = useNavigate();
    const [t] = useTranslation("global");
    let title = t('productLocationComponent.productLocation');
    const handlerApp = HandlerApp();
    const profile = handlerApp.getProfile()

    const [buttonSelected, setButtonSelected] = useState(false);
    const [rowsSelected, setRowsSelected] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const [taxgroup, setTaxesGroup] = useState([]);
    const [printers, setPrinters] = useState([]);
    const [idToEdit, setIdToEdit] = useState([])
    const [editValues, setEditValues] = useState({});

    const { register, formState: { errors } } = useForm()

    // Permisos
    const [permissions,setPermissions] = useState([]);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1010);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 1010);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    const {app, modeTheme} = useContext(AppContext);
    useEffect(()=>{
        setPermissions(app.permissions[endpoint])
    }, [app])

    // location
    const { location,company } = useContext(LocationContext);
    useEffect(() => {
        if (location === "" && profile > 3) {
            if (handlerApp.getLocation() === null || handlerApp.getLocation() === "") {
                handlerApp.showAlertLocation();
                setData([]);
            }
        } else if (location !== "" || company) {
            sendRequest();
            getParamsUpdate();
        }else{
            sendRequest();
        }
    }, [location,company])

    const sendRequest = () => {
        setData([])
        handlerApp.setLoader(true);
        api.get(endpoint, {
            location,
            company
        })
            .then(response => {
                if (response.success) {
                    setData(response.data);
                    handlerApp.setLoader(false);
                    
                } else {
                    setData([]);
                    if (response.message !== "M001") {
                        handlerApp.handlerResponse(response);
                    }
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
            })
    }
    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                deleteRecords(id)
                break;
            case 'deleteSelected':
                const selectedIds = rowsSelected.map(row => row.productlocation_id);
                deleteRecords(selectedIds);
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                sendRequest();
                break;
            case 'activateSelected':
                updateSelectedRowsStatus(1);
                break;
            case 'deactivateSelected':
                updateSelectedRowsStatus(0);
                break;
            case 'editSelected':
                const selectedEditIds = rowsSelected.map(row => row.productlocation_id);
                setIdToEdit(selectedEditIds)
                break;
        }
    }
    const optionRequest = (id, action) => {
        Swal.fire({
            title: action === 'active' ? `${t('activeAlert.activeRecord')}` : `${t('inactiveAlert.inactiveAlert')}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: action === 'active' ? "#08a215" : "#be2a2a",
            cancelButtonColor: "#636c74",
            cancelButtonText: `${t('cancel')}`,
            confirmButtonText: action === 'active' ? `${t('activeAlert.active')}` : `${t('inactiveAlert.inactive')}`
        }).then((result) => {
            if (result.isConfirmed) {
                executeAction(id, action);
            }
        });
    }
    
    const executeAction = (id, action) => {
        let body = {
            action: 'updateStatus',
            status: (action === 'active') ? 1 : 0
        }
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, body)

            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    sendRequest();
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }
    
    const deleteRecords = (ids) => {
        const isMultiple = Array.isArray(ids);
        const alertTitle = isMultiple ? t('DeleteAlert.DeleteSelectedRecords') : t('DeleteAlert.DeleteRecord');
        const endpointUrl = isMultiple ? `${endpoint}/all` : `${endpoint}/${ids}`;
        let body = {
            codes: ids
        }
        Swal.fire({
            icon: 'info',
            title: alertTitle,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: t('DeleteAlert.delete'),
            confirmButtonColor: "red",
            cancelButtonText: t('cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
    
                const request = isMultiple 
                    ? api.deleteMethod(endpointUrl, body) 
                    : api.deleteMethod(endpointUrl);
    
                request
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            if (isMultiple) {
                                setButtonSelected(false);
                                setRowsSelected([]);
                                handleClearRows();
                            }
                            sendRequest();
                        } else {
                            handlerApp.handlerResponse(response);
                            handlerApp.setLoader(false);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                        handlerApp.setLoader(false);
                    });
            }
        });
    };  

    const updateSelectedRowsStatus = (status) => {
        const actionName = status === 1 ? 'activateSelected' : 'deactivateSelected';
        const actionTitle = status === 1 ? `${t('activeAlert.activateSelectedRecords')}` : `${t('inactiveAlert.inactiveSelectedRecords')}`;
        Swal.fire({
            icon: 'info',
            title: actionTitle,
            showCancelButton: true,
            confirmButtonText: status === 1 ? `${t('activeAlert.active')}` : `${t('inactiveAlert.inactive')}`,
            confirmButtonColor: status === 1 ? "#08a215" : "#d32f2f",
            cancelButtonText: `${t('cancel')}`
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                const ids = rowsSelected.map(row => row.productlocation_id);
                const body = {
                    action: 'updateStatus',
                    status: status,
                    codigos: ids
                };
                api.put(endpoint+'/all', body)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            setButtonSelected(false);
                            setRowsSelected([]);
                            sendRequest();
                            handleClearRows();
                        } else {
                            handlerApp.handlerResponse(response);
                        }
                        handlerApp.setLoader(false);
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                        handlerApp.setLoader(false);
                    });
            }
        });
    };

    const addUniqueElements = (sourceArray, targetArray) => {
        const combinedArray = targetArray.concat(sourceArray);
        const syncedArray = combinedArray.filter(item => sourceArray.includes(item));
        const newArray = [...new Set(syncedArray)]
        let keysToDelete = combinedArray.filter(item => !newArray.includes(item));

        // Eliminar elementos duplicados de keysToDelete
        keysToDelete = [...new Set(keysToDelete)];

        // Eliminar los objetos correspondientes del objeto original
        keysToDelete.forEach(key => {
            delete editValues[key];
        });
        return [...new Set(syncedArray)];
    };

    const rowSelected = (state) => {
        if (state.selectedRows.length > 0 && idToEdit.length > 0) {
            const rows = state.selectedRows
            const ids = rows.map(row => row.productlocation_id)
            const updatedArray = addUniqueElements(ids, idToEdit);
            setIdToEdit(updatedArray);
        }else{
            idToEdit.forEach(key => {
                delete editValues[key];
            });
            setIdToEdit([]);
        }
        setButtonSelected(state.selectedRows.length > 0);
        setRowsSelected(state.selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    };

    // filtros barra de busqueda.
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    useEffect(() => {
        if (Array.isArray(data)) {
            let filter = data?.filter(
                item => item.product_name && item.product_name?.toLowerCase().includes(filterText.toLowerCase())
                    || item.location_name?.toString().toLowerCase().includes(filterText.toLowerCase())
                    || item.company_name?.toLowerCase().includes(filterText.toLowerCase())
                    || item.product_price?.toString().includes(filterText.toLowerCase())
                    || item.productlocation_stock?.toString().includes(filterText.toLowerCase())
                    || item.productlocation_min_stock?.toString().includes(filterText.toLowerCase())
                    || item.productlocation_id?.toString().includes(filterText.toLowerCase())
                    || item.printer_name?.toLowerCase().includes(filterText.toLowerCase())
            );
            setFilteredItems(filter);
        }
    }, [data, filterText])

    const productNotAvailable = (productlocation_id) => {
        let table = data;
        let elemento = 0;
        let status = false;
        table.forEach((element, index) => {
            if (element.productlocation_id == productlocation_id) {
                elemento = index;
                status = !element.productlocation_not_available;
                // setData(...table,table[index].product_not_available = !element.productlocation_not_available);
                table[index].productlocation_not_available = status;
            }
        });
        setData([...data, table]);

        // handlerApp.setLoader(true);
        let body = {
            action: 'productNotAvailable',
            status
        }
        api.put(endpoint + "/" + productlocation_id, body)
            .then(response => {
                // success
            })
            .catch(error => {
                table[elemento].productlocation_not_available = !status;
                setData([...data, table]);
            })

    }

    const handleUpdateProducts = (body) => {
        handlerApp.setLoader(true);
        api.put(endpoint + "/all", body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    setIdToEdit([])
                    setButtonSelected(false);
                    setRowsSelected([]);
                    setEditValues([])
                    handleClearRows();
                    sendRequest()
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const getParamsUpdate = () => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: 'getParamsUpdate',
            location
        })
            .then(response => {
                if (response.success) {
                    setTaxesGroup(handlerApp.getResultFromObject(response.data, 'tax_group'));
                    setPrinters(handlerApp.getResultFromObject(response.data, 'printers'))
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleEditChange = (id, name, value,product_id) => {
        const isValidNumberInput = (name, value) => {
            if (name === "product_price" || name === "productlocation_stock") {
                const regex = /^\d+(\.\d{0,2})?$/;
                return regex.test(value) || value === '';
            }
            return true; // Para otros campos, siempre es válido
        };
        if (isValidNumberInput(name, value)) {
            setEditValues(prevState => ({
                ...prevState,
                [id]: {
                    ...prevState[id],
                    [name]: value,
                    product_id
                }
            }));
        }
    };

    const handleSave = () => {
        const filteredObj = Object.keys(editValues)
        .filter(key => idToEdit.includes(parseInt(key)))
        .reduce((res, key) => {
            res[key] = editValues[key];
            return res;
        }, {});
        try {
            if (Object.keys(filteredObj).length > 0) {
                for (const [_, value] of Object.entries(filteredObj)) {
                    if (value.printer_id === "" || value.product_name === "") {
                        throw new Error("StopIteration");
                    }
                }
                handleUpdateProducts(filteredObj)
            }
        } catch (error) {
            if (error.message === "StopIteration") {
                handlerApp.showError("tableActionsMassives.requiredFields");
            }
        }
    };
    const columns = [
        {
            id: 'productlocation_id',
            name: 'ID',
            selector: row => row.productlocation_id,
            sortable: true,
            width: "65px",
        },
        {
            id: 'productlocation_image',
            name: t('productLocationComponent.image'),
            cell: (row) => {
                return (
                    <>
                        {row.images ? ( // Si company_logo existe y no es nulo
                            <img className="bold-image-table" src={row.images} alt={row.images} />
                        ) : ( // Si company_logo no existe o es nulo
                            <img className="bold-image-table" src={imageNotFound} alt="Default" />
                        )}
                    </>
                );
            },
            sortable: false,
            width: "100px"
        },
        {
            id: 'product_name',
            name: t('productLocationComponent.product_name'),
            selector: row => {
                const isEditing = idToEdit.includes(row.productlocation_id);
                let value = isEditing && editValues[row.productlocation_id]?.product_name !== undefined
                    ? editValues[row.productlocation_id].product_name
                    : row.product_name;

                return isEditing ? (
                    <EditTextCell id={row.productlocation_id} name={'product_name'} data={value} product={row.product_id}  onChange={handleEditChange}/>
                ) : row.product_name;
            },
            sortable: true,
        },
        ...(profile <= 2 ? [
            {
                id: 'company_name',
                name: t('posComponent.company'),
                selector: row => row.company_name,
                sortable: true
            }
        ] : []),
        {
            id: 'location_name',
            name: t('posComponent.location'),
            selector: row => row.location_name,
            sortable: true
        },
        {
            name: t('taxesComponent.tax_associated'),
            selector: row => row.taxes, // row.taxes contiene el JSON de los taxes.
            sortable: false,
            width: "200px",
            cell: (row) => {
                const isEditing = idToEdit.includes(row.productlocation_id);

                if (isEditing) {
                    let value;
                    if (editValues[row.productlocation_id]?.taxgroup_id) {
                        value = editValues[row.productlocation_id]?.taxgroup_id
                    }else{
                        if (editValues[row.productlocation_id]?.taxgroup_id === '') {
                            value =  ''
                        }else{
                            value = row.taxgroup_id
                        }
                    }
                    return (
                        <div className="col-md-12 mb-2">
                            <BoldSelectValidated
                                title={t('taxesComponent.taxgroup_name')}
                                value={value}
                                onChange={(e) => handleEditChange(row.productlocation_id, 'taxgroup_id', e.target.value,row.product_id)}
                                name="taxgroup_id"
                                options={taxgroup}
                                register={register}
                                errors={errors}
                                disable={location == ''}
                            />
                        </div>
                    )
                }else{
                    // Analiza el JSON y crea un string HTML o contenido personalizado.
                    const taxesArray = row.taxes;
    
                    if (taxesArray.length === 0) return 'N/A'
    
                    const taxContent = taxesArray.map((tax) => {
                        return `<div className="small-text">${tax.tax_name}: ${tax.tax_percentage}%</div>`;
                    }).join('');
    
                    return (
                        <div dangerouslySetInnerHTML={{ __html: taxContent }} />
                    );
                }

            },
        },
        {
            id: 'productlocation_stock',
            name: t('productComponent.stock'),
            selector: row =>{ 
                const isEditing = idToEdit.includes(row.productlocation_id);

                let value = isEditing && editValues[row.productlocation_id]?.productlocation_stock !== undefined
                ? editValues[row.productlocation_id].productlocation_stock
                : row.productlocation_stock;

                return isEditing ? (
                    <EditTextCell id={row.productlocation_id} name={'productlocation_stock'} data={value} product={row.product_id} onChange={handleEditChange}/>
                ) : row.productlocation_stock;

            },
            sortable: true,
        },
        {
            id: 'product_price',
            name: t('productComponent.price'),
            // cell: (row) => {return handlerApp.formatCurrency(row.productlocation_price)},
            selector: (row) => {
                const isEditing = idToEdit.includes(row.productlocation_id);
                let value = isEditing && editValues[row.productlocation_id]?.product_price !== undefined
                ? editValues[row.productlocation_id].product_price
                : row.product_price;
                if (isEditing) {
                    return <EditTextCell id={row.productlocation_id} name={'product_price'} data={value} product={row.product_id}  onChange={handleEditChange}/>
                }else{
                    if (row.productlocation_discount > 0) {
                        return (
                            <div>
                                <p className="margin-cero-padding-cero">{handlerApp.formatCurrency(row.productlocation_price - (row.productlocation_price * (row.productlocation_discount / 100)))}</p>
                                <p style={{ textDecorationLine: 'line-through', textDecorationColor: 'red' }}>{handlerApp.formatCurrency(row.productlocation_price)}</p>
                            </div>
                        )
                    } else {
                        return (handlerApp.formatCurrency(row.product_price))
                    }
                }
            },
            sortable: true,
            width: "100px"
        },
        {
            id: 'printer_name',
            name: t('printersComponent.printers'),
            selector: row => {
                const isEditing = idToEdit.includes(row.productlocation_id);
                if (isEditing) {
                    let value;
                    if (editValues[row.productlocation_id]?.printer_id) {
                        value = editValues[row.productlocation_id]?.printer_id
                    }else{
                        if (editValues[row.productlocation_id]?.printer_id === '') {
                            value =  ''
                        }else{
                            value = row.printer_id
                        }
                    }
                    return (
                        <div className="col-md-12 mb-2">
                            <BoldSelectValidated
                                title={t('printersComponent.printer')+" *"}
                                value={value}
                                onChange={(e) => handleEditChange(row.productlocation_id, 'printer_id', e.target.value, row.product_id)}
                                name="printer_id"
                                options={printers}
                                register={register}
                                errors={errors}
                                required={true}
                                disable={location == ''}
                            />
                        </div>
                    )
                }else{
                    return row.printer_name
                }

            },
            sortable: true,
            minwidth: "50px"
        },
        {
            name: t('action'),
            cell: (row) => {
                if (idToEdit.length === 0) {
                    return <>
                        {permissions?.indexOf('update') &&
                            <div>
                                {row.productlocation_not_available == true &&
                                    <button className="btn-table-actions wx2" title="Product not available" onClick={e => { productNotAvailable(row.productlocation_id) }} id={row.ID}
                                        style={{display: 'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                        <span className="material-symbols-outlined inactive">production_quantity_limits</span>
                                        <p style={{fontSize: '8px', margin: '0', padding: '0', textAlign: 'center', whiteSpace: 'nowrap', marginTop: '4px'}}>{t('productComponent.notAvailable')}</p>
                                    </button>
                                }
                                {row.productlocation_not_available == false &&
                                    <button className="btn-table-actions wx2" title="Product available" onClick={e => { productNotAvailable(row.productlocation_id) }} id={row.ID}
                                    style={{display: 'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                        <span className="material-symbols-outlined active">production_quantity_limits</span>
                                        <p style={{fontSize: '8px', margin: '0', padding: '0', textAlign: 'center', marginTop: '4px'}}>{t('productComponent.available')}</p>
                                    </button>
                                }
                            </div>
                        }
                        {handlerApp.actions(row, permissions, 'productlocation', handlerAction)}
                    </>
                }else{
                    return t('tableActionsMassives.editing')
                }
            },
            ignoreRowClick: true,
            button: true.toString(),
            minwidth: "200px"
        }
    ];

    const handleFilter = (action) => {
        switch (action) {
            case 'active':
                sendFilter('active', 1);
                break;
            case 'inactive':
                sendFilter('active', 0);
                break;
            case 'bestSeller':
                sendFilter('bestSeller', 1);
                break;
            case 'worstSeller':
                sendFilter('bestSeller', 0);
                break;
        }
    }

    const sendFilter = (action, value) => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            location,
            action,
            value
        })
            .then(response => {
                if (response.success) {
                    setData(response.data);
                    handlerApp.setLoader(false);
                } else {
                    setData([]);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
            })
    }

    const handleCancelEdit = () => { 
        idToEdit.forEach(key => {
            delete editValues[key];
        });
        setIdToEdit([])
        setButtonSelected(false);
        setRowsSelected([]);
        handleClearRows();
    }

    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

    return (
        <div className="app container">
            <div className="title-section-forms">
                <h3>{title}</h3>
                <p>{t("description.productLocation")} </p>
            </div>
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t('search')}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            {!isSmallScreen && buttonSelected && (
                                <>
                                    <div style={{ display: 'flex', gap: '10px' }} className="mass-action-buttons">
                                        {idToEdit.length == 0 && (
                                            <>
                                                {permissions?.indexOf("inactive") !== -1 && (
                                                    <>
                                                        <button className="btn-table-actions-massives inactive" onClick={() => { handlerAction(null, 'deactivateSelected') }}>
                                                            <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>toggle_off</span>
                                                            {t("tableActionsMassives.inactive")}
                                                        </button>
                                                        <button className="btn-table-actions-massives active" onClick={() => { handlerAction(null, 'activateSelected') }}>
                                                            <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>toggle_on</span>
                                                            {t("tableActionsMassives.active")}
                                                        </button>
                                                    </>
                                                )}
                                                {permissions?.indexOf("delete") !== -1 && (
                                                    <button className="btn-table-actions-massives delete" onClick={() => { handlerAction(null, 'deleteSelected') }}>
                                                        <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>delete</span>
                                                        {t("tableActionsMassives.delete")}
                                                    </button>
                                                )}
                                            </>
                                        )}
                                        {idToEdit.length > 0 ? (
                                            <>
                                                <button className="btn-table-actions-massives save" onClick={handleSave}>
                                                    <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>check</span>
                                                    {t("tableActionsMassives.save")}
                                                </button>
                                                <button className="btn-table-actions-massives cancel" onClick={handleCancelEdit}>
                                                    <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>close</span>
                                                    {t("cancel")}
                                                </button>
                                            </>
                                        ) : profile > 2 && (
                                            
                                            <button className="btn-table-actions-massives edit" onClick={() => { handlerAction(null, 'editSelected') }}>
                                                <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>edit_square</span>
                                                {t("tableActionsMassives.quickEdit")}
                                            </button>
                                        )}
                                    </div>
                                </>
                            )}
                            <div className="action-buttons">
                                <button className="btn-table-actions" onClick={() => { handlerAction(null, 'create') }}>
                                    <span className="material-symbols-outlined">add</span>
                                </button>
                                <button className="btn-table-actions" onClick={() => { handlerAction(null, 'refresh') }}>
                                    <span className="material-symbols-outlined">autorenew</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    {isSmallScreen && buttonSelected && (
                        <>
                            <div style={{ display: 'flex', gap: '10px' }} className="mass-action-buttons">
                                {idToEdit.length == 0 && (
                                    <>
                                        {permissions?.indexOf("inactive") !== -1 && (
                                            <>
                                                <button className="btn-table-actions-massives inactive" onClick={() => { handlerAction(null, 'deactivateSelected') }}>
                                                    <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>toggle_off</span>
                                                    {t("tableActionsMassives.inactive")}
                                                </button>
                                                <button className="btn-table-actions-massives active" onClick={() => { handlerAction(null, 'activateSelected') }}>
                                                    <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>toggle_on</span>
                                                    {t("tableActionsMassives.active")}
                                                </button>
                                            </>
                                        )}
                                        {permissions?.indexOf("delete") !== -1 && (
                                            <button className="btn-table-actions-massives delete" onClick={() => { handlerAction(null, 'deleteSelected') }}>
                                                <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>delete</span>
                                                {t("tableActionsMassives.delete")}
                                            </button>
                                        )}
                                    </>
                                )}
                                {idToEdit.length > 0 ? (
                                    <>
                                        <button className="btn-table-actions-massives save" onClick={handleSave}>
                                            <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>check</span>
                                            {t("tableActionsMassives.save")}
                                        </button>
                                        <button className="btn-table-actions-massives cancel" onClick={handleCancelEdit}>
                                            <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>close</span>
                                            {t("cancel")}
                                        </button>
                                    </>
                                ) : (
                                    <button className="btn-table-actions-massives edit" onClick={() => { handlerAction(null, 'editSelected') }}>
                                        <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>edit_square</span>
                                        {t("tableActionsMassives.quickEdit")}
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        subHeader
                        noDataComponent={<NoDataTables />}
                        selectableRows={(permissions?.indexOf("inactive") !== -1 || permissions?.indexOf("delete") !== -1)}
                        onSelectedRowsChange={rowSelected}
                        clearSelectedRows={toggledClearRows}
                        
                        selectableRowsComponent={Checkbox}
                        selectableRowsComponentProps={selectProps}
                        theme={modeTheme ? "solarized" : ""}
                    />
                </div>
            </div>
        </div>
    )
}
export default ProductLocation2;