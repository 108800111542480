import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import imageNotFound from "../../../assets/img/image-not-found.webp";


const ProductsView = () => {
    const endpoint = "administration/products";
    const [t, i18n] = useTranslation("global");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState({});
    const [title, setTitle] = useState("");
    const [image, setImage] = useState("");
    const nav = useNavigate();    
    const language = localStorage.getItem('languageLoc') 


    const { id } = useParams();

    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
            .then(response => {
                if (response.success) {
                    handlerApp.setLoader(false);
                    response.data[0].images = JSON.parse(response.data[0].images)
                    let data = response.data[0];
                    setTitle(data.product_name);
                    let extras = response.data[0].extras;
                    response.data[0].extras = printData(extras, "item_name");
                    response.data[0].images.length > 0 && setImage(response.data[0].images.filter((image)=>image.image_type == 'main')[0].image_url)
                    let ingredients = response.data[0].ingredients;
                    response.data[0].ingredients = printData(ingredients, "item_name");
                    setData(data);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }, [])

    const printData = (data, key) => {
        let str = "";
        data.forEach((element, index) => {
            if (index == 0) {
                str += element[key];
            } else {
                str += "," + element[key];
            }
        });
        return str;
    }

    const back = () => {
        nav(-1);
    }
    return (
        <div className="app">
            <div className="container bold-container-content2">
                <div className="d-flex align-items-center back-arrow">
                    <div className="d-flex row justify-content-between align-items-center arrow-back-icon2 ">
                        <span className="material-symbols-outlined arrowBack backArrow " role="button" onClick={back}>arrow_back</span>
                    </div>
                    <div style={{marginLeft: '10px'}}>{title}</div>
                </div>
            </div>
            <div className="container bold-container-content">
                <div className="row np-row">
                    {(data.images?.length <= 0 || data.images === undefined || data.images === null) &&
                        <>
                            <div className="col-md-4 d-flex mb-4">
                                <section className="section-image-view" style={{ backgroundImage: `url('${imageNotFound}')` }}>
                                </section>
                            </div>
                        </>
                    }
                    {data.images?.length > 0 && data.images !== undefined && data.images !== null &&
                        <>
                            <div className="col-md-4 d-flex mb-4">
                                <section className="section-image-view" style={{ backgroundImage: `url('${image}')` }}>
                                </section>
                            </div>
                        </>
                    }
                    <div className="col-md-8">
                        <div className="row">
                        {language == 1 ?
                            <div className="col-md-6 mb-2">
                                <dt>{t('productComponent.product')}:</dt>
                                <dd>{data.product_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>:
                            <div className="col-md-6 mb-2">
                                <dt>{t('productComponent.productSpanishName')}:</dt>
                                <dd>{data.product_name_spanish || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>}
                            <div className="col-md-6 mb-2">
                                <dt>{t('categoryComponent.category')}:</dt>
                                <dd>{data.category_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('subcategoryComponent.subcategory')}:</dt>
                                <dd>{data.subcategory_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>SKU:</dt>
                                <dd>{data.product_sku || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('productComponent.price')}:</dt>
                                <dd>{handlerApp.formatCurrency(data.product_price) }</dd>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-2">
                        <dt>{t('productComponent.cost')}:</dt>
                        <dd>{handlerApp.formatCurrency(data.product_cost) || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-4 mb-2">
                        <dt>{t('productComponent.currency')}:</dt>
                        <dd>{data.product_currency || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-4 mb-2">
                        <dt>{t('productComponent.productView')}:</dt>
                        <dd>{data.product_view || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-12 mb-2">
                        <dt>{t('productComponent.link')}:</dt>
                        <dd>{data.product_link || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    {language == 1 ?
                    <div className="col-md-12 mb-2">
                        <dt>{t('englishDescription')}</dt>
                        <dd>{data.product_description || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>:
                    <div className="col-md-12 mb-2">
                        <dt>{t('spanishDescription')}</dt>
                        <dd>{data.product_description_spanish || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>}
                    <div className="form-title col-md-12">
                        {t('productComponent.additionalInformation')}
                    </div>
                    <div className="col-md-12 mb-2">
                        <dt>Extras</dt>
                        <dd>{data.extras || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-12 mb-2">
                        <dt>{t('productComponent.ingredients')}</dt>
                        <dd>{data.ingredients || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ProductsView;