
import { TextField } from "@mui/material";
import { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import BoldFilesUpload from "../../../utils/BoldFilesUpload";
import HandlerApp from "../../../utils/handlerApp";
import { useForm, Controller } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InputAdornment from '@mui/material/InputAdornment';
import { Description } from "@mui/icons-material";

const ItemForms = () => {
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const endpoint = "configuration/items";
    const [t, i18n] = useTranslation("global");
    const [title, setTitle] = useState(t('itemComponent.createItem'));
    const [description, setDescription] = useState('');
    // Permisos
    const handlerApp = HandlerApp();
    const profile = handlerApp.getProfile()
  
    const [permissions, setPermissions] = useState([]);
    const { app } = useContext(AppContext);
    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        setTitle(t('itemComponent.createItem'));
        setDescription(t('description.item_create'));
        if (id !== undefined) {
            setTitle(t('itemComponent.updateItem'));
            setDescription(t('description.item_update'));
        }
    }, [t])
    // utils
    const api = useFetch();
    const nav = useNavigate();
    const { id } = useParams();

    // estado inicial de formulario
    const [formData, setFormData] = useState({
        company_id: "",
        item_name: "",
        item_name_spanish: "",
        item_cost: "",
        item_price: "",
        item_currency: "",
        item_image: "",
        item_description: "",
        item_description_spanish: "",
        item_details: []
    });

    
    // Companies
    const [companies, setCompanies] = useState([]);
    const [disableSelectCompany, setDisableSelectCompany] = useState(false);


    const [images, setImages] = useState([]);
    const handleImages = (e) => {
        setImages(e)
    }

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];

            setValue(key, data[key]);
        }
        return finalData;
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        let value = e.target.value;
    
        const regex = /^\d+(\.\d{0,2})?$/;
        const isInputNumber = key === "item_cost" || key === "item_price"

        const updateFormData = (key, value) => {
            setFormData(prevState => ({
              ...prevState,
              [key]: value
            }));
        
            setValue(key, value);
        
            if (dependency) {
              dependency(value);
            }
        };
        if (!isInputNumber || (isInputNumber && (regex.test(value) || value === ''))) {
            updateFormData(key, value);
        }
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }


    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
            .then(response => {
                if (response.success) {
                    setCompanies(handlerApp.getResultFromObject(response.data, 'companies'));

                    if (id !== undefined) {
                        sendRequest();
                        setDisableSelectCompany(true)
                    } else {
                        handlerApp.setLoader(false);
                    }
                } else {
                    handlerApp.setLoader(false);
                }

            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError();
            }
            )
    }, [])

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitLocal = (data) => {
        let body = { ...formData, images };
        handlerApp.setLoader(true);
        api.post(endpoint, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }

            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleUpdate = (data) => {
        handlerApp.setLoader(true);
        // seteo imagenes
        let body = { ...formData, images };
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleKeyDow = (event) => {
        // Verifica si la tecla presionada es Enter
        if (event.key === 'Enter') {
            // Evita el comportamiento predeterminado del formulario
            event.preventDefault();
        }
    };

    return (
        <div className="app container">
            <div className="title-section-forms">
                {title}
                <p>{description}</p>
            </div>
            
            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitLocal : handleUpdate)} noValidate onKeyDown={handleKeyDow}>
                    <div className="row">
                        {profile<=2 &&(
                            <div className="col-md-12 mb-2">
                                <BoldSelectValidated
                                    title={t('companyComponent.company') + " *"}
                                    value={formData.company_id ?? ''}
                                    name="company_id"
                                    options={companies}
                                    onChange={handleForm}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                    disable={disableSelectCompany}
                                />
                            </div>
                        )}
                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('itemComponent.englishName')+" *"} variant="standard" size="small"
                                {...register("item_name", { required: true, maxLength: 120 })}
                                value={formData.item_name ?? ''}
                                onChange={handleForm}
                                error={errors.item_name != null}
                                helperText={
                                    errors.item_name?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.item_name?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 120  ${t('validationErrorMessages.longSuffix')}` : ""}
                            />
                            {/* <TextField name="item_name" onChange={handleForm} value={formData.item_name ?? ''} label={t('itemComponent.englishName')} required variant="standard" size="small" /> */}
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('itemComponent.spanishName')+" *"} variant="standard" size="small"
                                {...register("item_name_spanish", { required: true, maxLength: 120 })}
                                value={formData.item_name_spanish ?? ''}
                                onChange={handleForm}
                                error={errors.item_name_spanish != null}
                                helperText={
                                    errors.item_name_spanish?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.item_name_spanish?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 120  ${t('validationErrorMessages.longSuffix')}` : ""}
                            />
                            {/* 
                            <TextField name="item_name_spanish" onChange={handleForm} value={formData.item_name_spanish ?? ''} label={t('itemComponent.spanishName')} required variant="standard" size="small" /> */}
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('itemComponent.itemCost')}
                                variant="standard"
                                size="small"
                                {...register("item_cost", {

                                    min: 0,
                                    max: 2147483647
                                })}
                                value={formData.item_cost ?? ''}
                                onChange={handleForm}
                                error={errors.item_cost != null}
                                type="number"
                                multiline={true}
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 2147483647
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyIcon />

                                        </InputAdornment>
                                    )
                                }}
                                helperText={
                                    errors.item_cost?.type === 'min'
                                        ? `${t('validationErrorMessages.minNumber')} 0 `
                                        : errors.item_cost?.type === 'max'
                                            ? `${t('validationErrorMessages.MaxNumber')} 2147483647 `
                                            : ""
                                }
                            />
                            {/* 
                            <TextField name="item_cost" type="number" onChange={handleForm} value={formData.item_cost ?? ''} label={t('itemComponent.itemCost')} variant="standard" size="small" /> */}
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('itemComponent.itemPrice')+" *"}
                                variant="standard"
                                size="small"
                                {...register("item_price", {
                                    required: true,
                                    min: 0,
                                    max: 2147483647
                                })}
                                value={formData.item_price ?? ''}
                                onChange={handleForm}
                                error={errors.item_price != null}
                                type="number"
                                multiline={true}
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 2147483647
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyIcon />

                                        </InputAdornment>
                                    )
                                }}
                                helperText={
                                    errors.item_price?.type === 'required'
                                        ? t('validationErrorMessages.required')
                                        : errors.item_price?.type === 'min'
                                            ? `${t('validationErrorMessages.minNumber')} 0 `
                                            : errors.item_price?.type === 'max'
                                                ? `${t('validationErrorMessages.MaxNumber')} 2147483647 `
                                                : ""
                                }
                            />
                            {/* <TextField name="item_price" type="number" onChange={handleForm} value={formData.item_price ?? ''} label={t('itemComponent.itemPrice')} required variant="standard" size="small" /> */}
                        </div>

                        <div className="col-md-12 mb-2">
                            <TextField
                                label={t('englishDescription')}
                                size="small"
                                {...register("item_description", { maxLength: 500 })}
                                value={formData.item_description ?? ''}
                                onChange={handleForm}
                                multiline rows={3}
                                error={errors.item_description != null}
                                helperText={
                                    errors.item_description?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 500 ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                            {/* <TextField name="item_description" onChange={handleForm} value={formData.item_description ?? ''} label={t('englishDescription')} multiline rows={3} size="small" /> */}
                        </div>

                        <div className="col-md-12 mb-2">
                            <TextField
                                label={t('spanishDescription')}
                                size="small"
                                {...register("item_description_spanish", { maxLength: 500 })}
                                value={formData.item_description_spanish ?? ''}
                                onChange={handleForm}
                                multiline rows={3}
                                error={errors.item_description_spanish != null}
                                helperText={
                                    errors.item_description_spanish?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 500 ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                            {/* <TextField name="item_description_spanish" onChange={handleForm} value={formData.item_description_spanish ?? ''} label={t('spanishDescription')} multiline rows={3} size="small" /> */}
                        </div>

                        <div className="col-md-12 mb-2">
                            <BoldFilesUpload returnImages={handleImages} multiple={false} value={formData.item_image ?? ''} />
                        </div>

                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ItemForms;